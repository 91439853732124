import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Reuse the existing CSS or create a new one as per your need
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";

// Sample random data for wire buyers
const WireBuys = [
  {
    _id: "66869a299f2cecbc7cc11440",
    name: "Amit",
    email: "sales@spectrama.com.sg",
    mobileNumber: "+65 6268 7728",
    lookingFor: "Bulk Purchase: High-Quality Cable Ties Required!",
    location: "Singapore",
    date: "02-04-2025",
    productOrService: "Cable Ties Supplier",
    quantity: 2000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11441",
    name: "Rajesh",
    email: "enquiry@wilson-cables.com.sg",
    mobileNumber: "+65 68617888",
    lookingFor: "Large Order: Industrial Cable Ties Needed",
    location: "Singapore",
    date: "03-04-2025",
    productOrService: "Cable Ties Dealer",
    quantity: 5000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11442",
    name: "Sandeep",
    email: "info@lamhongs.com.sg",
    mobileNumber: "+65 6265 6010",
    lookingFor: "Urgent Order: Heavy-Duty Cable Ties Required",
    location: "Singapore",
    date: "04-04-2025",
    productOrService: "Cable Ties Wholesaler",
    quantity: 3000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11443",
    name: "Marco",
    email: "contact@fastpointweb.it",
    mobileNumber: "+39 041 462435",
    lookingFor: "Looking for Premium Cable Ties",
    location: "Italy",
    date: "02-04-2025",
    productOrService: "Cable Ties Manufacturer",
    quantity: 4000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11444",
    name: "Hans",
    email: "info@emc-direct.de",
    mobileNumber: "+49 2369 98469-0",
    lookingFor: "High-Quality Cable Ties Needed for Industrial Use",
    location: "Germany",
    date: "03-04-2025",
    productOrService: "Cable Ties Distributor",
    quantity: 6000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11445",
    name: "John",
    email: "sales@ctie.com",
    mobileNumber: "+44 1737 760645",
    lookingFor: "Bulk Order of Durable Cable Ties",
    location: "United Kingdom",
    date: "04-04-2025",
    productOrService: "Cable Ties Retailer",
    quantity: 4500,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11446",
    name: "Alex",
    email: "sales@infinitecables.com",
    mobileNumber: "+1 905-477-4433",
    lookingFor: "Immediate Supply of Nylon Cable Ties",
    location: "Canada",
    date: "01-04-2025",
    productOrService: "Cable Ties Supplier",
    quantity: 7000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11447",
    name: "Michael",
    email: "sales@actfs.com",
    mobileNumber: "+1 978 630 3999",
    lookingFor: "Heavy-Duty Cable Ties Needed for Electrical Work",
    location: "USA",
    date: "02-04-2025",
    productOrService: "Cable Ties Supplier",
    quantity: 8000,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11448",
    name: "David",
    email: "jaguar@jaguarind.com",
    mobileNumber: "(845) 947-1800",
    lookingFor: "Wholesale Order for Standard Cable Ties",
    location: "USA",
    date: "03-04-2025",
    productOrService: "Cable Ties Manufacturer",
    quantity: 5500,
    unit: "pcs",
  },
  {
    _id: "66869a299f2cecbc7cc11436",
    name: "Amit",
    email: "amitverma@gmail.com",
    mobileNumber: "9012567834",
    lookingFor: "Bulk Purchase: High Quality Copper Wire Required!",
    location: "Mumbai",
    date: "03-04-2025",
    productOrService: "Wire Supplier",
    quantity: 1000,
    unit: "Units",
  },
  {
    _id: "66869a299f2cecbc7cc11437",
    name: "Manoj",
    email: "manoj.sharma@gmail.com",
    mobileNumber: "9987654321",
    lookingFor: "Urgent Requirement: Steel Wire - Large Quantity",
    location: "Jaipur",
    date: "01-04-2025",
    productOrService: "Wire Dealer",
    quantity: 1500,
    unit: "Units",
  },
  {
    _id: "66869a299f2cecbc7cc11438",
    name: "Pooja",
    email: "pooja.agarwal@gmail.com",
    mobileNumber: "9123456789",
    lookingFor: "Immediate Need: Electrical Wire - 500Units Required",
    location: "Chennai",
    date: "04-04-2025",
    productOrService: "Electrical Wire Wholesaler",
    quantity: 500,
    unit: "Units",
  },
];

const WireBuyes = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    // Load the viewed leads from local storage
    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`wireViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
        console.log("Premium Status:", data.isPremium);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return; // Only premium users can see numbers

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return; // If already shown, do nothing

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    // Update local storage
    localStorage.setItem(
      `wireViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = WireBuys[index]; // Get the buyer information

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item"
          >
            <Link to={"/"}>Home</Link>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px -25px",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item active"
          >
            Fresh Leads
          </li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3 style={{ marginTop: "30px" }}>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Karnataka</Link>
            </li>
            <li>
              <Link to="#">West Bengal</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
          </ul>

          <ul style={{ marginTop: "20px" }} className="flt-list cust-scroll">
            <h3>All Category</h3>
            <li>
              <Link to="#">Copper Wire</Link>
            </li>
            <li>
              <Link to="#">Steel Wire</Link>
            </li>
            <li>
              <Link to="#">Electrical Wire</Link>
            </li>
            <li>
              <Link to="#">Maize</Link>
            </li>
            <li>
              <Link to="#">Rice</Link>
            </li>
            <li>
              <Link to="#">Wheat</Link>
            </li>
            <li>
              <Link to="#">Barley</Link>
            </li>
            <li>
              <Link to="#">Soybean</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {WireBuys.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "red" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location </strong> {buyer.location}
              </p>
              <p>
                <strong>Date </strong> {buyer.date}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                {userIsPremium && (
                  <button
                    className="detail-btn"
                    style={{
                      padding: "5px 10px",
                      background: "#f7f1f1",
                      color: "orange",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleNumber(index)}
                  >
                    Click to see
                  </button>
                )}
              </div>
              <div className="btns-imp">
                <Link to={"/login"}>
                  <button className="detailing-btn">View More</button>
                </Link>
                <Link to={"/register-Company"}>
                  <button className="regis-btn">Register Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="sidebar">
          <div className="mains-slide">
            <div className="tk23-hkCont_onergt">
              <div className="tk23-hkCont_premserv__box">
                <h1 className="tk23-hkCont_premserv__ttl">
                  Our Membership Plans
                </h1>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Standard Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Advance Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Premium Plan
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Whyglobal />
      </div>
    </>
  );
};

export default WireBuyes;
