import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    _id: "m_satheesh_dsk_agro",
    name: "Mr. M Satheesh Kumar",
    email: "9865908483@gmail.com",
    password: "9865908483",
    mobileNumber: "9865908483",
    companyName: "DSK AGRO",
    statename: "Tamil Nadu",
    cityname: "Theni",
    productOrService: "Tender Coconuts, Brown Coconuts, Copra Coconuts",
    imgSrc: "/assets/coconut10.jpg",
    mainProducts: "Tender Coconuts, Brown Coconuts, Copra",
    altText: "DSK Agro - Supplier of Coconuts",
    years: "1 YRS",
    location: "Theni, Tamil Nadu, India",
    tooltipText: "Mr. M Satheesh Kumar - Coconut Supplier",
    rating: "4.8",
    ratingPercent: "95%",
    ratingsCount: "130",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "veeramani_amudhasurabi",
    name: "Mr. Veeramani",
    email: "9080667001@gmail.com",
    password: "9080667001",
    mobileNumber: "9080667001",
    companyName: "Amudhasurabi Enterprises",
    statename: "Tamil Nadu",
    cityname: "Pollachi, Coimbatore",
    productOrService: "Husked Coconut, Tender Coconut, Raw Coconut",
    imgSrc: "/assets/coconut2.jpg",
    mainProducts: "Husked Coconut, Tender Coconut, Raw Coconut",
    altText: "Amudhasurabi Enterprises - Supplier of Husked & Tender Coconuts",
    years: "1 YRS",
    location: "Pollachi, Tamil Nadu",
    tooltipText: "Mr. Veeramani - Supplier of Quality Coconuts",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "180",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
  {
    _id: "s_kumaresan_jk_vishwa_traders",
    name: "Mr. S. Kumaresan",
    email: "8012924588@gmail.com",
    password: "8012924588",
    mobileNumber: "8012924588",
    companyName: "JK Vishwa Traders",
    statename: "Tamil Nadu",
    cityname: "Tiruchirappalli",
    productOrService: "Coconuts - Coconut Flower, Husked Coconut, Coconut Seed",
    imgSrc: "/assets/coconut3.jpg",
    mainProducts: "Coconuts, Coconut Flower, Husked Coconut, Coconut Seed",
    altText: "JK Vishwa Traders - Supplier of Quality Coconut Products",
    years: "1 YRS",
    location: "Tiruchirappalli, Tamil Nadu",
    tooltipText: "Mr. S. Kumaresan - Trusted Supplier of Coconut Products",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "150",
    responseRate: "87%",
    whatsappConfirmed: false,
  },
  {
    _id: "mr_mariappan_mkp_enterprises",
    name: "Mr. A. Mariappan",
    email: "8754080719@gmail.com",
    password: "8754080719",
    mobileNumber: "8754080719",
    companyName: "MKP Enterprises",
    statename: "Tamil Nadu",
    cityname: "Tirunelveli",
    productOrService:
      "Coconut, Coconut Shell, Coconut Husk, Cocopeat Powder, Coco Wedpith",
    imgSrc: "/assets/coconut4.jpg",
    mainProducts: "Coconut & Coconut Derivatives",
    altText: "MKP Enterprises - Supplier of Quality Coconut Products",
    years: "1 YRS",
    location: "Tirunelveli, Tamil Nadu",
    tooltipText:
      "Mr. A. Mariappan - Trusted Supplier of Coconut & Its Derivatives",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "180",
    responseRate: "88%",
    whatsappConfirmed: false,
  },
];

const CoconutProducts = () => {
  return (
    <div className="main-box">
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Related Categories</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Apples</Link>
                </li>
                <li>
                  <Link to="#">Oranges</Link>
                </li>
                <li>
                  <Link to="#">Bananas</Link>
                </li>
                <li>
                  <Link to="#">Grapes</Link>
                </li>
                <li>
                  <Link to="#">Mangoes</Link>
                </li>
                <li>
                  <Link to="#">Berries</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flt-box">
            <p className="flt-title">By State</p>
            <div className="flt-content">
              <div className="flt-search">
                <input
                  type="text"
                  name="state_id"
                  placeholder="Search State"
                  id="state-search-input"
                />
              </div>
              <ul className="flt-list cust-scroll" id="state-lists">
                <li>
                  <Link to="#">All India</Link>
                </li>
                <li>
                  <Link to="#">Gujarat</Link>
                </li>
                <li>
                  <Link to="#">Maharashtra</Link>
                </li>
                <li>
                  <Link to="#">Karnataka</Link>
                </li>
                <li>
                  <Link to="#">Punjab</Link>
                </li>
                <li>
                  <Link to="#">Uttar Pradesh</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <main>
        <section className="section">
          <p className="sect-title">Explore by Categories</p>
          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/apple1.jpeg"
                      width={55}
                      height={55}
                      alt="Apples"
                    />
                  </figure>
                  <p className="title">Apples</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/oranges.jpeg"
                      width={55}
                      height={55}
                      alt="Oranges"
                    />
                  </figure>
                  <p className="title">Oranges</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/banana.jpeg"
                      width={55}
                      height={55}
                      alt="Bananas"
                    />
                  </figure>
                  <p className="title">Bananas</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/grapes.jpeg"
                      width={55}
                      height={55}
                      alt="Grapes"
                    />
                  </figure>
                  <p className="title">Grapes</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/mango.jpeg"
                      width={55}
                      height={55}
                      alt="Mangoes"
                    />
                  </figure>
                  <p className="title">Mangoes</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <ul className="classfied-wrap">
          {classifiedData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to={item.path}>
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div
                        className="ca-box modal-btn"
                        data-modal="motc"
                        data-src={item.trustCertificateUrl}
                      >
                        <p>
                          <i className="l3icon motc-icon" />
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default CoconutProducts;
