import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    _id: "i3456789012abc7890",
    name: "Md Irfan Ansari",
    email: "ansari123@gmail.com",
    password: "ansari1234",
    mobileNumber: "7905636511",
    companyName: "A M Sons",
    productOrService: "Kurti",
    imgSrc: "/assets/kurti1.jpeg", // Placeholder for image path
    mainProducts: "Designer Kurtis, Cotton Kurtis, Embroidered Kurtis",
    altText: "A M Sons - Kurti Collection",
    years: "1 YRS", // Placeholder for years of experience
    location: "Jaipur, Rajasthan, India", // Placeholder for location
    tooltipText: "A M Sons, Jaipur",
    rating: "4.6", // Placeholder for rating
    ratingPercent: "92%", // Placeholder for rating percentage
    ratingsCount: "78", // Placeholder for ratings count
    responseRate: "87%", // Placeholder for response rate
    whatsappConfirmed: true,
  },
  {
    _id: "b1234567890xyz5681",
    name: "My Style Look",
    email: "rb7610992@gmail.com",
    password: "8058632003",
    mobileNumber: "8058632003",
    companyName: "My Style Look",
    productOrService: "Kurtis",
    imgSrc: "/assets/kurti2.jpeg",
    mainProducts: "Designer Kurtis, Ethnic Wear, Women's Apparel",
    altText: "My Style Look - Kurtis",
    years: "1 YRS",
    location: "Jaipur, Rajasthan, India",
    tooltipText: "My Style Look, Jaipur",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "85",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "b1c2d3e4f5g6h7i8j9k0l1m2", // Random unique ID
    name: "Pulkit Khandelwal",
    email: "shrinathdistributor9@gmail.com",
    mobileNumber: "7849811870",
    companyName: "Shri Nath Distributors",
    productOrService: "Garments",
    imgSrc: "/assets/garment4.jpeg", // Example image path
    mainProducts:
      "Men's Wear, Women's Wear, Kids' Clothing, Fashion Accessories",
    altText: "Garments - Shri Nath Distributors",
    years: "1 YRS",
    location: "Delhi, India",
    tooltipText: "23 Garment Lane, Delhi, India",
    rating: "4.3",
    ratingPercent: "88%",
    ratingsCount: "60",
    responseRate: "91%",
    whatsappConfirmed: true,
  },
  {
    name: "Silambarasan",
    email: "8610812075@gmail.com",
    password: "8610812075",
    mobileNumber: "8610812075",
    companyName: "Ik Enterprise",
    productOrService: "Clay Handicraft, Honey, Nuts",
    imgSrc: "/assets/garment7.jpg",
    mainProducts: "Clay Handicrafts, Honey, Nuts",
    altText: "Ik Enterprise - Clay Handicraft, Honey, Nuts",
    years: "1 YRS",
    location: "Tiruvallur, Tamil Nadu, India",
    tooltipText: "Ik Enterprise, Tamil Nadu",
    rating: "4.2",
    ratingPercent: "84%",
    ratingsCount: "60",
    responseRate: "90%",
    whatsappConfirmed: false,
  },
  {
    _id: "vmg-fashion-delhi",
    name: "Vijay",
    email: "abc@gmail.com",
    password: "vijay@123",
    mobileNumber: "9310704440",
    companyName: "VMG Fashion",
    productOrService: "Ladies Kurti",
    imgSrc: "/assets/vmg.jpg", // Placeholder image path
    mainProducts: "Ladies Kurti",
    altText: "VMG Fashion - Ladies Kurti",
    years: "1 YRS", // Random value
    location: "Delhi, Delhi, India",
    tooltipText: "VMG Fashion, Ladies Kurti, Delhi",
    rating: "4.7", // Random value
    ratingPercent: "93%", // Random value
    ratingsCount: "35", // Random value
    responseRate: "85%", // Random value
    whatsappConfirmed: true,
  },
  {
    _id: "farhaz_sunshinelaser",
    name: "Farhaz",
    email: "SUNSHINELASERS@GMAIL.COM",
    password: "8375000867",
    mobileNumber: "8433875466",
    companyName: "Sunshine Laser",
    statename: "Maharashtra",
    cityname: "Mumbai",
    productOrService: "Kurti",
    imgSrc: "/assets/kurti12.jpg",
    mainProducts: "Designer Kurtis, Ethnic Wear, Custom Embroidery",
    altText: "Sunshine Laser - Expert in Designer Kurtis & Ethnic Wear",
    years: "1 YRS",
    location: "Mumbai, Maharashtra",
    tooltipText: "Farhaz - Specialized in Laser Cutting & Ethnic Wear Design",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "180",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
];

const KurtiDealerss = () => {
  return (
    <>
      <div className="main-box">
        <aside>
          <div className="flt-box-wrap">
            <div className="flt-box mb-0 flt-head">Filters By</div>
            <div className="flt-box bdrt-0">
              <p className="flt-title">Related Categories</p>
              <div className="flt-content">
                <ul className="flt-list cust-scroll">
                  <li>
                    <Link to="#">Men's Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Women's Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Kids' Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Ethnic Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Formal Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Casual Wear</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flt-box">
              <p className="flt-title">By State</p>
              <div className="flt-content">
                <div className="flt-search">
                  <input
                    type="text"
                    name="state_id"
                    placeholder="Search State"
                    id="state-search-input"
                  />
                </div>
                <ul className="flt-list cust-scroll" id="state-lists">
                  <li>
                    <Link to="#">All India</Link>
                  </li>
                  <li>
                    <Link to="#">Maharashtra</Link>
                  </li>
                  <li>
                    <Link to="#">Gujarat</Link>
                  </li>
                  <li>
                    <Link to="#">Delhi</Link>
                  </li>
                  <li>
                    <Link to="#">Tamil Nadu</Link>
                  </li>
                  <li>
                    <Link to="#">Uttar Pradesh</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <main>
          <section className="section">
            <p className="sect-title">Explore by Categories</p>
            <div className="horprd expcatg" id="expcatg">
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/gr1.jpeg"
                        width={55}
                        height={55}
                        alt="Men's Wear"
                      />
                    </figure>
                    <p className="title">Men's Wear</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/gr2.jpeg"
                        width={55}
                        height={55}
                        alt="Women's Wear"
                      />
                    </figure>
                    <p className="title">Women's Wear</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/gr3.jpeg"
                        width={55}
                        height={55}
                        alt="Kids' Wear"
                      />
                    </figure>
                    <p className="title">Kids' Wear</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/gr5.jpeg"
                        width={55}
                        height={55}
                        alt="Ethnic Wear"
                      />
                    </figure>
                    <p className="title">Ethnic Wear</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/formal.jpg"
                        width={55}
                        height={55}
                        alt="Formal Wear"
                      />
                    </figure>
                    <p className="title">Formal Wear</p>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <ul className="classfied-wrap">
            {classifiedData.map((item, index) => (
              <li key={index}>
                <div className="classified">
                  <div className="prd-info">
                    <div className="prd-box">
                      <img
                        src={item.imgSrc}
                        alt={item.altText}
                        width={250}
                        height={250}
                      />
                    </div>
                  </div>
                  <div className="cinfo">
                    <div className="cbox">
                      <figure>
                        <span className="cmp-year">{item.years}</span>
                      </figure>
                      <div className="cboxr">
                        <Link to="#" target="_blank">
                          <h4 className="title">{item.companyName}</h4>
                        </Link>
                        <p className="cloc tooltip ellipsis">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi-location"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                          </svg>
                          {item.location}
                          <span className="tooltiptext">
                            {item.tooltipText}
                          </span>
                        </p>
                        <div className="rating-wrap">
                          <span className="rtbox">{item.rating}</span>
                          <span
                            className="crate"
                            style={{ "--_score": item.ratingPercent }}
                          />
                          <span className="rate-text">
                            {item.ratingsCount} Ratings
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="caddit">
                      <div className="item">
                        <div className="ca-box">
                          <p>
                            <i className="l3icon resp-icon" />
                          </p>
                          <p>
                            <span>Response Rate</span>{" "}
                            <b>{item.responseRate}</b>
                          </p>
                        </div>
                        <p>
                          <span>Main Products:</span> <b>{item.mainProducts}</b>
                        </p>
                      </div>
                    </div>
                    <div className="caction">
                      <Link to={"/register-buyer"}>
                        <p>Contact Supplier</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </main>
      </div>
      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Kurti Exporters | GlobalB2BMart
        </h2>

        <p class="section-content">
          GlobalB2BMart welcomes you, your reliable online portal for the best
          kurti exporters and apparel. Our website is designed to bring
          businesses and reliable suppliers/exporters together under one
          umbrella for all your apparel needs. We deal with suppliers who
          provide a variety of kurtis in various styles, fabrics, and designs
          that match different market needs. Whether you are a retailer,
          distributor, or designer, our platform offers access to verified and
          experienced suppliers who make garments with care and precision.
        </p>
        <p class="section-content">
          At GlobalB2BMart, we are proud to offer you a platform that brings you
          in touch with trustworthy kurti exporters. Our mission is to assist
          your business in finding apparel solutions that are uniform in quality
          and aligned with the latest fashion trends. Our kurti collection spans
          from the conventional to contemporary cuts. Our suppliers produce
          apparel in fabrics including cotton, silk, georgette, rayon, and other
          materials. They offer items suitable for bulk orders as well as
          boutique orders. Each kurti is crafted with utmost attention to detail
          and workmanship so that you get clothes that are fit for today's
          fast-paced business era.
        </p>
        <p class="section-heading">
          <strong>Best Indian Kurti Exporters</strong>
        </p>
        <p class="section-content">
          <b>Best Indian Kurti Exporters</b> are a vital component of our
          network. These kurtis showcase the rich cultural heritage of India and
          resonate with contemporary fashion sensibilities. They emphasise clear
          design details and accurate workmanship. The kurtis in this segment
          are ideal for local retail and export markets. Their collections
          feature everyday wear, festive wear, and formal wear options.
          Stringent quality control and sound production techniques ensure that
          each piece is up to industry standard.
        </p>
        <p class="section-content">
          In addition, these <b>kurti exporters </b>offer flexible ordering
          options that help businesses meet specific market needs. Many also
          provide customisation services, allowing clients to modify designs to
          suit unique preferences. Their efficient production processes and
          reliable delivery schedules make them dependable partners for both
          small retailers and large distribution networks. With years of
          experience and a focus on traditional methods combined with modern
          style, these exporters continue to build strong reputations. Their
          commitment to quality ensures that every kurti reflects the enduring
          appeal of Indian craftsmanship while meeting the high expectations of
          today’s fashion-conscious consumers.
        </p>
        <p class="section-heading">
          <strong>Affordable Kurti Exporters</strong>
        </p>
        <p class="section-content">
          <b> Affordable Kurti Exporters</b> try to provide high-quality kurtis
          at rates that fit various business budgets. These exporters understand
          that companies need cost-effective alternatives without compromising
          on style. Their collections include a blend of vintage and modern
          designs, offering solutions suitable for mass orders as well as
          boutique collections, making them an excellent option for retailers
          who require a diverse range of products at affordable prices.
        </p>
        <p class="section-content">
          In addition, these exporters offer options for minor customisation,
          allowing buyers to adjust designs according to their target market’s
          preferences. They maintain consistent quality by following strict
          production processes and regular quality checks, ensuring that each
          kurti meets industry standards. With efficient order management and
          reliable delivery services, they support both small and large
          businesses, ensuring that products are received on time.
        </p>
        <p class="section-content">
          By using standard materials and proven production techniques, these
          exporters deliver kurtis that are both budget-friendly and stylish.
          Their collections enable businesses to refresh their inventory with a
          wide range of options that appeal to various customer segments. In
          summary,<b> affordable kurti exporters</b> provide a practical and
          cost-effective solution for retailers looking to source quality kurtis
          without overspending.
        </p>
        <p class="section-heading">
          <strong>High-Quality Kurti Exporters </strong>
        </p>
        <p class="section-content">
          <b>High-Quality Kurti Exporters</b> aim to make kurtis of the highest
          design and material standards. They utilize high-quality materials and
          advanced manufacturing processes to produce garments that are both
          long-lasting and stylish. Their quality is evident in every aspect of
          the kurti, from detailed stitching and fine finishing to intricate
          embroidery and thoughtfully crafted patterns. These exporters work
          closely with skilled artisans to ensure that each piece reflects a
          balance of tradition and modern fashion trends.
        </p>
        <p class="section-content">
          By sourcing from these suppliers, businesses can ensure that their
          products align with market demands and provide lasting value to
          customers. Many <b> kurti exporters </b>also offer a wide range of
          designs, colors, and fabric choices, making it easier for retailers to
          stock collections that appeal to different customer preferences.
          Additionally, bulk production capabilities and efficient supply chains
          help businesses receive consistent stock without delays. With a strong
          focus on quality control, these exporters help companies maintain a
          reputable image in the apparel industry while delivering stylish and
          durable kurtis to their customers.
        </p>
        <p class="section-heading">
          <strong>Best Trendy Kurtis for Woman</strong>
        </p>
        <p class="section-content">
          <b> Best Trendy Kurtis for Women </b>collections feature clothing that
          combines traditional with contemporary styles. The kurtis are created
          for the fashion-forward consumers of today. The designs vary from
          basic prints to more complex patterns, providing several choices for
          casual wear, festival wear, and formal wear. This category assists
          companies in targeting a broader audience by offering different styles
          that suit diverse tastes and requirements.
        </p>

        <p class="section-heading">
          <strong>Why Choose GlobalB2BMart?</strong>
        </p>
        <p class="section-content">
          Our mission at GlobalB2BMart is to present an authentic and
          transparent platform for sourcing high-quality apparel in India. We
          collaborate with authenticated suppliers and exporters having a rich
          experience in delivering high-quality garments. Our vast database
          comprises elaborate business listings, updated catalogues, and
          competitive quotes to ensure that you gain the most accurate and fresh
          information to make your purchasing decision.
        </p>
        <p class="section-content">
          Our emphasis on quality and price ensures that if you require trendy
          ladies readymade clothing, reliable <b>kurti exporters</b>, or a range
          of clothing products from trusted manufacturers, GlobalB2BMart has a
          solution for your enterprise. We aim to assist your development by
          providing a reliable network that makes transactions process easy,
          enabling you to establish a strong supply chain.
        </p>
        <p class="section-heading">
          <strong>Conclusion</strong>
        </p>
        <p class="section-content">
          In short, GlobalB2BMart is the perfect venue for companies looking for
          reliable apparel solutions in India. We have a vast network of
          suppliers handling all aspects of the clothing industry, including
          kurtis, suits, sarees, lehengas, and many more. We try to give you
          clear, accurate information and links that can assist you in sourcing
          quality products at a reasonable price.
        </p>
      </div>
    </>
  );
};

export default KurtiDealerss;
