import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Assuming a new CSS file for door handle buyer styles
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";
import BuyerSide from "./BuyerSidebuyer";

const DoorHandleBuyers = [
  {
    _id: "001",
    name: "Ashish Jain",
    email: "ashishjn00@gmail.com",
    mobileNumber: "+917742730370",
    lookingFor: "Urgent: All Types of Door Handles in Bulk",
    location: "Jhalawar",
    date: "04-04-2025",
    productOrService: "Door Handles",
    quantity: "Varied",
    unit: "pieces",
    company: "Jain Machinery Store",
  },
  {
    _id: "002",
    name: "Chetan",
    email: "akale5512@gmail.com",
    mobileNumber: "9822658247, +919371487233",
    lookingFor: "Immediate Need: 1000 Premium Door Handles",
    location: "Shegaon",
    date: "02-04-2025",
    productOrService: "Door Handles",
    quantity: 1000,
    unit: "pieces",
    company: "Wonder Home Decore",
  },
  {
    _id: "003",
    name: "Nilesh Parekh",
    email: "parekhsteel@gmail.com",
    mobileNumber: "8320281127",
    lookingFor: "Bulk Order: High-Quality Door Handles",
    location: "Vadodara",
    date: "01-04-2025",
    productOrService: "Door Handles",
    quantity: "Varied",
    unit: "pieces",
    company: "Parekh Steel (Hardware)",
  },
  {
    _id: "004",
    name: "ąཞı.452",
    email: "akashgupta28338@gmail.com",
    mobileNumber: "+918979340972",
    lookingFor: "Urgent: Heavy-Duty Door Handles",
    location: "Hyderabad",
    date: "15-03-2025",
    productOrService: "Door Handles",
    quantity: "Varied",
    unit: "pieces",
    company: "Rahul Traders",
  },
  {
    _id: "005",
    name: "Phani Venkat",
    email: "phanikatakam3257@gmail.com",
    mobileNumber: "7661979118",
    lookingFor: "Dealer Inquiry: Wholesale Door Handles",
    location: "Peddapuram",
    date: "25-03-2025",
    productOrService: "Door Handles",
    quantity: "Varied",
    unit: "pieces",
    company: "Sri Venkateswara Hardware, Plywood & General Stores",
  },
  {
    _id: "006",
    name: "Ganesh Dhakne",
    email: "ganeshdhakane06@gmail.com",
    mobileNumber: "8999909123",
    lookingFor: "Bulk Requirement: Stainless Steel Door Handles",
    location: "Beed",
    date: "14-12-2024",
    productOrService: "Door Handles",
    quantity: "Varied",
    unit: "pieces",
    company: "Navbhagya Consultants",
  },
  {
    _id: "007",
    name: "Umesh Kumar Sahni",
    email: "umeshkumarsahni9@gmail.com",
    mobileNumber: "+918608214533",
    lookingFor: "Immediate Order: Custom Door Handles",
    location: "Delhi",
    date: "29-06-2024",
    productOrService: "Door Handles",
    quantity: "Varied",
    unit: "pieces",
    company: "Self-Employed",
  },
  {
    _id: "008",
    name: "Vinay Kumar Saraf",
    email: "interiorcollectionshr@gmail.com",
    mobileNumber: "+919883076560",
    lookingFor: "High-Quality Designer Door Handles Needed",
    location: "Kolkata",
    date: "11-07-2024",
    productOrService: "Door Handles",
    quantity: "Varied",
    unit: "pieces",
    company: "Interior Collection",
  },
  {
    _id: "009",
    name: "Pardeep Rajak",
    email: "rajeshk09737@gmail.com",
    mobileNumber: "+919334303570",
    lookingFor: "Urgent: 20000 Pieces of Heavy-Duty Door Handles",
    location: "Patna",
    date: "03-02-2025",
    productOrService: "Door Handles",
    quantity: 20000,
    unit: "pieces",
    company: "Pyush Ply",
  },
  {
    _id: "010",
    name: "Akash",
    email: "hansrajbhatia1991@gmail.com",
    mobileNumber: "9001263930",
    lookingFor: "Bulk Order: Wooden & Stainless Steel Door Handles",
    location: "Churu, Rajasthan",
    date: "15-08-2024",
    productOrService: "Door Handles",
    quantity: "Bulk",
    unit: "pieces",
    company: "Farnichar Work",
  },
  {
    _id: "011",
    name: "Ashim",
    email: "ashim@example.com",
    mobileNumber: "9593684836",
    lookingFor: "Bulk Order: Antique Finish Door Handles",
    location: "Malda, West Bengal",
    date: "30-09-2024",
    productOrService: "Door Handles",
    quantity: "Bulk",
    unit: "pieces",
    company: "Self-Employed",
  },
  {
    _id: "012",
    name: "Vikas Kanaujia",
    email: "vikasdevkanaujia@gmail.com",
    mobileNumber: "+919838110175",
    lookingFor: "Immediate Requirement: Rust-Free Brass Door Handles",
    location: "Unnao",
    date: "18-10-2024",
    productOrService: "Door Handles",
    quantity: "Bulk",
    unit: "pieces",
    company: "Self-Employed",
  },
  {
    _id: "013",
    name: "Moolchand Sharma",
    email: "shreeonlinestore222@gmail.com",
    mobileNumber: "+918306883565",
    lookingFor: "Wholesale Inquiry: High-End Door Handles",
    location: "Kuchaman",
    date: "27-11-2024",
    productOrService: "Door Handles",
    quantity: "Bulk",
    unit: "pieces",
    company: "Y",
  },
  {
    _id: "014",
    name: "Mukesh",
    email: "nationalelectrichardware12@gmail.com",
    mobileNumber: "9987775232",
    lookingFor: "Urgent: 100 Sets of Premium Door Handles",
    location: "Mumbai",
    date: "04-04-2025",
    productOrService: "Door Handles",
    quantity: 100,
    unit: "sets",
    company: "National",
  },
  {
    name: "Nilesh Parekh",
    email: "parekhsteel@gmail.com",
    mobileNumber: "8320281127",
    location: "Vadodara",
    date: "21-01-2025",
    lookingFor: "Looking for bulk purchase of premium door handles.",
    productOrService: "Door Handles",
    quantity: 220,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_2",
  },
  {
    name: "ąཞı.452",
    email: "akashgupta28338@gmail.com",
    mobileNumber: "+918979340972",
    location: "Hyderabad",
    date: "21-01-2025",
    lookingFor: "Looking for bulk purchase of door handles.",
    productOrService: "Door Handles",
    quantity: 300,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_3",
  },
  {
    name: "Phani Venkat",
    email: "phanikatakam3257@gmail.com",
    mobileNumber: "7661979118",
    location: "Peddapuram",
    date: "20-01-2025",
    lookingFor: "Looking for bulk purchase of door handles. Dealer inquiry.",
    productOrService: "Door Handles",
    quantity: 400,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_4",
  },
  {
    name: "𝐆𝐚𝐧𝐞𝐬𝐡 𝐃𝐡𝐚𝐤𝐧𝐞",
    email: "ganeshdhakane06@gmail.com",
    mobileNumber: "8999909123",
    location: "Beed",
    date: "20-01-2025",
    lookingFor: "Looking for bulk purchase of door handles.",
    productOrService: "Door Handles",
    quantity: 500,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_5",
  },
  {
    name: "Umesh Kumar Sahni",
    email: "umeshkumarsahni9@gmail.com",
    mobileNumber: "+918608214533",
    location: "Delhi",
    date: "19-01-2025",
    lookingFor: "Looking for bulk purchase of door handles.",
    productOrService: "Door Handles",
    quantity: 899,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_6",
  },
  {
    name: "Vinay Kumar Saraf",
    email: "interiorcollectionshr@gmail.com",
    mobileNumber: "+919883076560",
    location: "Kolkata",
    date: "18-01-2025",
    lookingFor: "Looking for bulk purchase of door handles.",
    productOrService: "Door Handles",
    quantity: 3000,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_7",
  },
  {
    name: "Rajesh Sharma",
    email: "rajesh.sharma@gmail.com",
    mobileNumber: "9876543211",
    location: "Mumbai",
    date: "17-01-2025",
    lookingFor:
      "Looking for bulk purchase of premium door handles for a residential project.",
    productOrService: "Door Handles",
    quantity: 1000,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_1",
  },
  {
    name: "Pooja Verma",
    email: "pooja.verma@gmail.com",
    mobileNumber: "9876543212",
    location: "Chennai",
    date: "16-01-2025",
    lookingFor:
      "Searching for decorative door handles for an office renovation project.",
    productOrService: "Door Handles",
    quantity: 500,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_2",
  },
  {
    name: "Amit Khanna",
    email: "amit.khanna@gmail.com",
    mobileNumber: "9876543213",
    location: "Kolkata",
    date: "15-01-2025",
    lookingFor: "Looking for durable door handles for commercial buildings.",
    productOrService: "Door Handles",
    quantity: 700,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_3",
  },
  {
    name: "Sunita Patel",
    email: "sunita.patel@gmail.com",
    mobileNumber: "9876543214",
    location: "Ahmedabad",
    date: "14-01-2025",
    lookingFor: "Looking for ergonomic door handles for a hotel chain.",
    productOrService: "Door Handles",
    quantity: 1500,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_4",
  },
  {
    name: "Arun Mehta",
    email: "arun.mehta@gmail.com",
    mobileNumber: "9988776655",
    location: "Chandigarh",
    date: "06-12-2024",
    lookingFor: "Looking to purchase brass door handles for luxury villas.",
    productOrService: "Brass Door Handles",
    quantity: 300,
    unit: "pieces",
    _id: "unique_id_for_door_handle_buyer_1",
  },
  {
    name: "Sonia Kapoor",
    email: "sonia.kapoor@gmail.com",
    mobileNumber: "9876543210",
    location: "Mumbai",
    date: "19-11-2024",
    lookingFor: "Need stainless steel door handles for a commercial project.",
    productOrService: "Stainless Steel Door Handles",
    quantity: 500,
    unit: "pieces",
  },
  {
    name: "Raj Malhotra",
    email: "raj.malhotra@gmail.com",
    mobileNumber: "9123456789",
    location: "Bangalore",
    date: "18-11-2024",
    lookingFor: "Interested in modern door handles with unique designs.",
    productOrService: "Designer Door Handles",
    quantity: 200,
    unit: "pieces",
  },
  {
    name: "Pooja Sinha",
    email: "pooja.sinha@gmail.com",
    mobileNumber: "8901234567",
    location: "Kolkata",
    date: "17-11-2024",
    lookingFor:
      "Bulk purchase of aluminum door handles for residential buildings.",
    productOrService: "Aluminum Door Handles",
    quantity: 1000,
    unit: "pieces",
  },
  {
    name: "Vikram Singh",
    email: "vikram.singh@gmail.com",
    mobileNumber: "9012345678",
    location: "Hyderabad",
    date: "16-11-2024",
    lookingFor: "High-quality wooden door handles for boutique hotels.",
    productOrService: "Wooden Door Handles",
    quantity: 150,
    unit: "pieces",
  },
];

const DoorHandleBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`doorHandleViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
        console.log("Premium Status:", data.isPremium);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return;

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return;

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    localStorage.setItem(
      `doorHandleViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = DoorHandleBuyers[index];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/"}>Home</Link>
          </li>
          <li>/</li>
          <li className="breadcrumb-item active">Fresh Leads</li>
        </ol>
      </div>
      <div className="containersx">
        <div className="browsers">
          <h3>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Punjab</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {DoorHandleBuyers.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location:</strong> {buyer.location}
              </p>
              <p>
                <strong>Date:</strong> {buyer.date}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              {userIsPremium && (
                <button onClick={() => toggleNumber(index)}>
                  Click to see
                </button>
              )}
              <div className="btns-imp">
                <Link to={"/login"}>
                  <button className="detailing-btn">View More</button>
                </Link>
                <Link to={"/register-Company"}>
                  <button className="regis-btn">Register Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <BuyerSide />
      </div>
      <Whyglobal />
    </>
  );
};

export default DoorHandleBuy;
