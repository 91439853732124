import React from "react";
import { Helmet } from "react-helmet";
import "./commn.css";

const Fashion = () => {
  const products = [
    {
      title: "Beachwear",
      imageSrc: "/assets/app1.jpg",
      links: [
        { name: "Swimsuits", url: "#" },
        { name: "Cover-ups", url: "#" },
        { name: "Boardshorts", url: "#" },
        { name: "Beach Dresses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Women's Clothing",
      imageSrc: "/assets/app2.jpg",
      links: [
        { name: "Dresses", url: "#" },
        { name: "Skirts", url: "#" },
        { name: "Blouses", url: "#" },
        { name: "Jumpsuits", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Hand Gloves & Mittens",
      imageSrc: "/assets/app3.jpg",
      links: [
        { name: "Leather Gloves", url: "#" },
        { name: "Winter Mittens", url: "#" },
        { name: "Touchscreen Gloves", url: "#" },
        { name: "Driving Gloves", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Embroidered Apparel & Garments",
      imageSrc: "/assets/app4.jpg",
      links: [
        { name: "Embroidered Jackets", url: "#" },
        { name: "Embroidered Skirts", url: "#" },
        { name: "Embroidered Blouses", url: "#" },
        { name: "Embroidered Shawls", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Fashion Garments",
      imageSrc: "/assets/app5.webp",
      links: [
        { name: "Evening Gowns", url: "#" },
        { name: "Cocktail Dresses", url: "#" },
        { name: "Blazers", url: "#" },
        { name: "Jumpsuits", url: "#" },
        { name: "View More", url: "#" },
      ],
    },

    {
      title: "Industrial Clothing & Work Wear",
      imageSrc: "/assets/app9.jpg",
      links: [
        { name: "Coveralls", url: "#" },
        { name: "High-Visibility Vests", url: "#" },
        { name: "Work Boots", url: "#" },
        { name: "Hard Hats", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Infant & Baby Clothing",
      imageSrc: "/assets/app8.jpg",
      links: [
        { name: "Baby Onesies", url: "#" },
        { name: "Baby T-Shirts", url: "#" },
        { name: "Baby Leggings", url: "#" },
        { name: "Baby Dresses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Kids Clothing & Dresses",
      imageSrc: "/assets/app10.jpg",
      links: [
        { name: "Kids T-Shirts", url: "#" },
        { name: "Kids Jeans", url: "#" },
        { name: "Kids Dresses", url: "#" },
        { name: "Kids Jackets", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Animal Clothing & Accessories",
      imageSrc: "/assets/app12.avif",
      links: [
        { name: "Pet Coats", url: "#" },
        { name: "Horse Blankets", url: "#" },
        { name: "Dog Sweaters", url: "#" },
        { name: "Cat Harnesses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Best clothing and apparel suppliers in india | globalb2bmart
        </title>{" "}
        {/* Title Tag */}
        <meta
          name="description"
          content="Globalb2bmart is the best b2b portal in india and is ready to connect you with some big clothing and apparel suppliers in india"
        />{" "}
        {/* Meta Description */}
      </Helmet>

      <div className="pagetitle">
        <h1>
          Your Premier Destination for Apparel & Fashion Industry Connections &
          B2B Trade Hub
        </h1>
      </div>
      <div className="l1-section">
        <div className="container">
          <div className="row l1-box-wrap">
            {products.map((product, index) => (
              <div key={index} className="col-4">
                <div className="l1-box">
                  <h2 className="l1-heading">
                    <a href="#">{product.title}</a>
                  </h2>
                  <div className="d-flex">
                    <div>
                      <figure className="cp" onClick={() => {}}>
                        <img
                          src={product.imageSrc}
                          alt={product.title}
                          width={95}
                          height={95}
                          loading="lazy"
                        />
                      </figure>
                    </div>
                    <div>
                      <ul>
                        {product.links.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a href={link.url}>{link.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Fashion;
