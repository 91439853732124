import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const riceDealersData = [
  {
    _id: "portovo-exim-llp-kolkata",
    name: "Aranya Mandal",
    email: "AranyaMandal@gmail.com",
    password: "123456789",
    mobileNumber: "9937477878",
    companyName: "PORTOVO EXIM LLP",
    statename: "West Bengal",
    cityname: "Kolkata",
    productOrService: "Rice",
    imgSrc: "/assets/rice8.jpg",
    mainProducts: "Rice",
    altText: "PORTOVO EXIM LLP - Kolkata, West Bengal",
    years: "1 YRS",
    location: "Kolkata, West Bengal",
    tooltipText: "PORTOVO EXIM LLP, Kolkata",
    rating: "4.2",
    ratingPercent: "84%",
    ratingsCount: "80",
    responseRate: "91%",
    whatsappConfirmed: true,
    path: "/portovo-rices",
  },
  {
    _id: "patel-trading-company-indore",
    name: "Sachin Kumar Patel",
    email: "SachinKumarPatel@gmail.com",
    password: "123456789",
    mobileNumber: "7409081681",
    companyName: "PATEL TRADING COMPANY",
    statename: "Madhya Pradesh",
    cityname: "Indore",
    productOrService: "Rice",
    imgSrc: "/assets/patel-rice1.jpg",
    mainProducts: "Rice",
    altText: "PATEL TRADING COMPANY - Indore, Madhya Pradesh",
    years: "1 YRS",
    location: "Indore, Madhya Pradesh",
    tooltipText: "PATEL TRADING COMPANY, Indore",
    rating: "4.1",
    ratingPercent: "82%",
    ratingsCount: "67",
    responseRate: "89%",
    whatsappConfirmed: true,
    path: "/patel-rices",
  },
  {
    _id: "indian-store-noida",
    name: "Vicky",
    email: "INDIANSTORE@gmail.com",
    password: "123456789",
    mobileNumber: "8432712160",
    companyName: "INDIAN STORE",
    statename: "Delhi",
    cityname: "Noida",
    productOrService: "Rice",
    imgSrc: "/assets/rice9.jpg",
    mainProducts: "Rice",
    altText: "INDIAN STORE - Noida",
    years: "1 YRS",
    location: "Noida, Delhi",
    tooltipText: "INDIAN STORE, Noida",
    rating: "4.3",
    ratingPercent: "86%",
    ratingsCount: "75",
    responseRate: "91%",
    whatsappConfirmed: true,
    path: "/indian-rices-patel",
  },
  {
    _id: "sila-con-mix-kerala",
    name: "Rajeev",
    email: "SilaConMix@gmail.com",
    password: "123456789",
    mobileNumber: "8589898222",
    companyName: "Sila Con Mix",
    statename: "Kerala",
    cityname: "Cherukunnam",
    productOrService: "Rice",
    imgSrc: "/assets/sila-rice.jpg",
    mainProducts: "Rice",
    altText: "Sila Con Mix - Kerala",
    years: "1 YRS",
    location: "Cherukunnam, Kerala",
    tooltipText: "Sila Con Mix, Kerala",
    rating: "4.1",
    ratingPercent: "82%",
    ratingsCount: "65",
    responseRate: "90%",
    path: "/sila-con-rice",
  },
  {
    name: "Salim Mohammed",
    email: "abc@gmail.com",
    password: "salim123",
    mobileNumber: "9358448864",
    companyName: "Rice Supplier",
    productOrService: "Rice",
    imgSrc: "/assets/rice20.jpg",
    mainProducts: "Rice, Rice Grains, Organic Rice",
    altText: "Rice Supplier - Premium Rice Products",
    years: "1 YRS",
    location: "Kuchaman City, Rajasthan, India",
    tooltipText: "Rice Supplier, Kuchaman City",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "120",
    responseRate: "90%",
    whatsappConfirmed: true,
    path: "/modi-care-rice",
  },

  {
    name: "Geetanjali",
    email: "Geetanjali@gmail.com",
    mobileNumber: "7032855846",
    companyName: "Modi Care",
    productOrService: "Rice Dealer",
    imgSrc: "/assets/rice1.jpeg", // Provide the correct image path
    altText: "Rice Dealer - Modi Care",
    mainProducts: "Basmati Rice, Sona Masoori Rice, Brown Rice, etc.",
    years: "1 YRS",
    location: "Delhi, India",
    tooltipText: "789, Chandni Chowk, Delhi - 110006, India",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "180",
    responseRate: "96%",
    path: "/modi-care-rice",
  },
  {
    _id: "j1234567890k012347",
    name: "Ashok Gangadhar Shinde",
    email: "ashok765@gmail.com",
    password: "ashok@123",
    mobileNumber: "9049860144",
    companyName: "Kaveri Gul V Sheti Utpadane",
    productOrService: "Rice & Agro Product Exporter",
    imgSrc: "/assets/rice3.jpeg",
    mainProducts: "Basmati Rice, Organic Rice, Agro Products",
    altText: "Rice & Agro Product Exporter - Kaveri Gul V Sheti Utpadane",
    years: "1 YRS",
    location: "Nashik, Maharashtra, India",
    tooltipText: "Sector 5, Agricultural Zone, Nashik, Maharashtra, India",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "60",
    responseRate: "90%",
    whatsappConfirmed: true,
    path: "/kaveri-rice",
  },
  {
    _id: "priyanshi-group-of-companies-gujarat",
    name: "Ranjan",
    email: "ranjan@gmail.com",
    password: "12345678",
    mobileNumber: "7667359829",
    companyName: "Priyanshi Group of Companies",
    productOrService: "Rice",
    imgSrc: "/assets/rice7.jpg",
    mainProducts: "Rice",
    altText: "Priyanshi Group of Companies - Rice",
    years: "1 YRS",
    location: "Gujarat, India",
    tooltipText: "Priyanshi Group of Companies, Rice, Gujarat",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "20",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    name: "Manish Goyal",
    email: "Manishgoyal@gmail.com",
    password: "12345678",
    mobileNumber: "9825800004",
    companyName: "Manish Goyal",
    productOrService: "Rice",
    imgSrc: "/assets/rice.jpeg",
    mainProducts: "Basmati Rice, Non-Basmati Rice, Organic Rice",
    altText: "Manish Goyal - Rice Supplier",
    years: "1 YRS",
    location: "Mundra, Gujarat, India",
    tooltipText: "Manish Goyal, Mundra",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "120",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "sakthi-sai-agencies",
    name: "Mr Mohan Kumar",
    email: "9361359643@gmail.com",
    password: "9361359643",
    mobileNumber: "9361359643",
    companyName: "Sakthi Sai Agencies",
    statename: "Tamil Nadu",
    cityname: "Chennai",
    productOrService:
      "Indian Rice, Indian Pulses, Indian Spices, Wheat Seeds, White Sugar",
    imgSrc: "/assets/rice10.jpg",
    mainProducts:
      "Indian Rice, Indian Pulses, Indian Spices, Wheat Seeds, White Sugar",
    altText:
      "Sakthi Sai Agencies - Supplier of Indian Rice, Pulses, Spices, Wheat Seeds, White Sugar",
    years: "1 YRS",
    location: "Chennai, Tamil Nadu, India",
    tooltipText:
      "Mr Mohan Kumar - Specialist in Indian Rice, Pulses, Spices, Wheat Seeds, White Sugar",
    rating: "4.8",
    ratingPercent: "95%",
    ratingsCount: "56",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    _id: "abhijeet_samasta_foods",
    name: "Abhijeet",
    email: "abhijeet@samastafoods.com",
    password: "samastafoods@123",
    mobileNumber: "9257029862",
    companyName: "Samasta Foods",
    statename: "Rajasthan",
    cityname: "Jaipur",
    productOrService: "Rice",
    imgSrc: "/assets/rice11.jpg",
    mainProducts: "Rice",
    altText: "Samasta Foods - Premium Rice Supplier",
    years: "1 YRS",
    location: "Jaipur, Rajasthan",
    tooltipText: "Abhijeet - Leading Supplier of High-Quality Rice",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "180",
    responseRate: "89%",
    whatsappConfirmed: false,
  },
  {
    _id: "abhijeet_samasta_foods",
    name: "Abhijeet",
    email: "abhijeet@samastafoods.com",
    password: "samastafoods@123",
    mobileNumber: "9257029862",
    companyName: "Samasta Foods",
    statename: "Rajasthan",
    cityname: "Jaipur",
    productOrService: "Rice",
    imgSrc: "/assets/rice23.jpg",
    mainProducts: "Rice",
    altText: "Samasta Foods - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Jaipur, Rajasthan",
    tooltipText: "Abhijeet - Leading Supplier of Premium Rice",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "200",
    responseRate: "90%",
    whatsappConfirmed: false,
  },
  {
    _id: "prerna_jajoo_organics",
    name: "Ms. Prerna Maheshwari",
    email: "jajooorganicsllp@gmail.com",
    password: "jajooorganicsllp@123",
    mobileNumber: "9057534001",
    companyName: "Jajoo Organics LLP",
    statename: "Maharashtra",
    cityname: "Mumbai",
    productOrService: "Rice",
    imgSrc: "/assets/rice22.jpg",
    mainProducts: "Rice",
    altText: "Jajoo Organics LLP - Supplier of High-Quality Organic Rice",
    years: "1 YRS",
    location: "Mumbai, Maharashtra",
    tooltipText: "Ms. Prerna Maheshwari - Leading Supplier of Organic Rice",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "150",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "kartikeya_alarm_trading",
    name: "Mr. Kartikeya Bairwa",
    email: "alarmtrading@gmail.com",
    password: "alarmtrading@123",
    mobileNumber: "9414986422",
    companyName: "Alarm Trading",
    statename: "Rajasthan",
    cityname: "Jaipur",
    productOrService: "Rice",
    imgSrc: "/assets/rice12.jpg",
    mainProducts: "Rice",
    altText: "Alarm Trading - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Jaipur, Rajasthan",
    tooltipText: "Mr. Kartikeya Bairwa - Trusted Supplier of Premium Rice",
    rating: "4.5",
    ratingPercent: "89%",
    ratingsCount: "180",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "sanjay_jeevika_samridhi_biotech",
    name: "Mr. Sanjay Kumar Yadav",
    email: "jeevikasamridhibiotech@gmail.com",
    password: "jeevikasamridhibiotech@123",
    mobileNumber: "7838698836",
    companyName: "Jeevika Samridhi Biotech",
    statename: "Bihar",
    cityname: "Bhojpur",
    productOrService: "Rice",
    imgSrc: "/assets/rice13.jpg",
    mainProducts: "Rice",
    altText: "Jeevika Samridhi Biotech - Supplier of High-Quality Rice",
    years: "1  YRS",
    location: "Bhojpur, Bihar",
    tooltipText: "Mr. Sanjay Kumar Yadav - Trusted Supplier of Premium Rice",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "150",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "shubham_js_export_import",
    name: "Mr. Shubham Singh Bhadouriya",
    email: "jsexportandimport@gmail.com",
    password: "jsexportandimport@123",
    mobileNumber: "7024454935",
    companyName: "Js Export And Import",
    statename: "Madhya Pradesh",
    cityname: "Indore",
    productOrService: "Rice",
    imgSrc: "/assets/rice14.jpg",
    mainProducts: "Rice",
    altText: "Js Export And Import - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Indore, Madhya Pradesh",
    tooltipText:
      "Mr. Shubham Singh Bhadouriya - Trusted Supplier of Premium Rice",
    rating: "4.5",
    ratingPercent: "89%",
    ratingsCount: "170",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "arivazhagan_sri_arivu_jothi_agro_farm",
    name: "Mr. Arivazhagan",
    email: "7305063479@gmail.com",
    password: "7305063479",
    mobileNumber: "7305063479",
    companyName: "Sri Arivu Jothi Agro Farm",
    statename: "Tamil Nadu",
    cityname: "Tiruchirappalli",
    productOrService: "Black Rice, Brown Rice, Red Rice, White Rice",
    imgSrc: "/assets/rice16.jpg",
    mainProducts: "Black Rice, Brown Rice, Red Rice, White Rice",
    altText: "Sri Arivu Jothi Agro Farm - Supplier of Organic Rice Varieties",
    years: "1 YRS",
    location: "Tiruchirappalli, Tamil Nadu",
    tooltipText:
      "Mr. Arivazhagan - Leading Supplier of Organic and Traditional Rice",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "185",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "shri_ashish_bhutada_asm_global_inc",
    name: "Mr. Shri Ashish Bhutada",
    email: "ASMGlobalINC@gmail.com",
    password: "ASMGlobalINC@123",
    mobileNumber: "9049801111",
    companyName: "ASM Global INC",
    statename: "Maharashtra",
    cityname: "Gondia",
    productOrService: "Rice",
    imgSrc: "/assets/rice15.jpg",
    mainProducts: "Rice",
    altText: "ASM Global INC - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Gondia, Maharashtra",
    tooltipText: "Mr. Shri Ashish Bhutada - Leading Supplier of Premium Rice",
    rating: "4.8",
    ratingPercent: "94%",
    ratingsCount: "210",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    _id: "ekjot_singh_amens_hospitality",
    name: "Mr. Ekjot Singh",
    email: "amenshospitalityprivatelimited@gmail.com",
    password: "amenshospitalityprivatelimited@123",
    mobileNumber: "8826769250",
    companyName: "Amen's Hospitality Private Limited",
    statename: "Haryana",
    cityname: "Gurugram",
    productOrService: "Rice",
    imgSrc: "/assets/rice24.jpg",
    mainProducts: "Rice",
    altText:
      "Amen's Hospitality Private Limited - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Gurugram, Haryana",
    tooltipText: "Mr. Ekjot Singh - Leading Supplier of Premium Rice",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "180",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
  {
    _id: "vk_manikandan_vkmani_trader",
    name: "Mr. V K Manikandan",
    email: "vkmanitraderprivatelimited@gmail.com",
    password: "vkmanitrader(OPC)privatelimited@123",
    mobileNumber: "8220932498",
    companyName: "Vkmani Trader (OPC) Private Limited",
    statename: "Tamil Nadu",
    cityname: "Tiruchirappalli",
    productOrService: "Rice",
    imgSrc: "/assets/rice25.jpg",
    mainProducts: "Rice",
    altText:
      "Vkmani Trader (OPC) Private Limited - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Tiruchirappalli, Tamil Nadu",
    tooltipText: "Mr. V K Manikandan - Leading Supplier of Premium Rice",
    rating: "4.5",
    ratingPercent: "88%",
    ratingsCount: "150",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "rakesh_sinha_shrija_international",
    name: "Mr. Rakesh Sinha",
    email: "shrijalnternational@gmail.com",
    password: "shrijalnternational@123",
    mobileNumber: "8048002285",
    companyName: "Shrija International",
    statename: "Jharkhand",
    cityname: "Ranchi, Namkum",
    productOrService: "Rice",
    imgSrc: "/assets/rice17.jpg",
    mainProducts: "Rice",
    altText: "Shrija International - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Ranchi, Jharkhand",
    tooltipText: "Mr. Rakesh Sinha - Leading Supplier of Premium Rice",
    rating: "4.6",
    ratingPercent: "90%",
    ratingsCount: "175",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
  {
    _id: "yogita_gaunker_vihaan_enterprises",
    name: "Ms. Yogita Gaunker",
    email: "Yogitagaunker@gmail.com",
    password: "vihaanenterprises@123",
    mobileNumber: "9673671133",
    companyName: "Vihaan Enterprises",
    statename: "Goa",
    cityname: "Panjim",
    productOrService: "Rice",
    imgSrc: "/assets/rice28.jpg",
    mainProducts: "Rice",
    altText: "Vihaan Enterprises - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Panjim, Goa",
    tooltipText: "Ms. Yogita Gaunker - Leading Supplier of Premium Rice",
    rating: "4.5",
    ratingPercent: "88%",
    ratingsCount: "160",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "kansara_dhrumin_trade_tales",
    name: "Mr. Kansara Dhrumin",
    email: "tradetales@gmail.com",
    password: "tradetales@123",
    mobileNumber: "9173293908",
    companyName: "Trade Tales",
    statename: "Gujarat",
    cityname: "Ahmedabad",
    productOrService: "Rice",
    imgSrc: "/assets/rice29.jpg",
    mainProducts: "Rice",
    altText: "Trade Tales - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Ahmedabad, Gujarat",
    tooltipText: "Mr. Kansara Dhrumin - Leading Supplier of Premium Rice",
    rating: "4.6",
    ratingPercent: "90%",
    ratingsCount: "180",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "mohd_shakeel_abdul_mustafa_amrl_group",
    name: "Mohd Shakeel & Mr. Abdul Mustafa",
    email: "resourcesamrl@gmail.com",
    password: "AMRLGROUP@123",
    mobileNumber: "9311078856",
    companyName: "AMRL GROUP",
    statename: "Karnataka",
    cityname: "Bangalore",
    productOrService: "Rice",
    imgSrc: "/assets/rice30.jpg",
    mainProducts: "Rice",
    altText: "AMRL GROUP - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Bangalore, Karnataka",
    tooltipText:
      "Mohd Shakeel & Mr. Abdul Mustafa - Leading Supplier of Premium Rice",
    rating: "4.5",
    ratingPercent: "89%",
    ratingsCount: "150",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "rajiv_rathi_varenyam_agro_gains",
    name: "Mr. Rajiv Rathi",
    email: "varenyamagrogains@gmail.com",
    password: "varenyamagrogains@123",
    mobileNumber: "9352177700",
    companyName: "Varenyam Agro Gains Private Limited",
    statename: "Rajasthan",
    cityname: "Jaipur",
    productOrService: "Rice",
    imgSrc: "/assets/rice31.jpg",
    mainProducts: "Rice",
    altText: "Varenyam Agro Gains - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Jaipur, Rajasthan",
    tooltipText: "Mr. Rajiv Rathi - Leading Supplier of Premium Rice",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "180",
    responseRate: "88%",
    whatsappConfirmed: false,
  },
  {
    _id: "rajinder_singh_dsr_overseas",
    name: "Mr. Rajinder Singh",
    email: "DSRoverseas@gmail.com",
    password: "",
    mobileNumber: "9041438260",
    companyName: "DSR Overseas",
    statename: "Punjab",
    cityname: "Batala",
    productOrService: "Rice",
    imgSrc: "/assets/rice27.jpg",
    mainProducts: "Rice",
    altText: "DSR Overseas - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Batala, Punjab",
    tooltipText: "Mr. Rajinder Singh - Leading Supplier of Premium Rice",
    rating: "4.5",
    ratingPercent: "89%",
    ratingsCount: "160",
    responseRate: "85%",
    whatsappConfirmed: false,
  },
  {
    _id: "raju_biswas_shibam_enterprise",
    name: "Mr. Raju Biswas",
    email: "rajukr0027@gmail.com",
    password: "shibamenterprise@123",
    mobileNumber: "9647163761",
    companyName: "Shibam Enterprise",
    statename: "West Bengal",
    cityname: "Parganas",
    productOrService: "Rice",
    imgSrc: "/assets/rice32.jpg",
    mainProducts: "Rice",
    altText: "Shibam Enterprise - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Parganas, West Bengal",
    tooltipText: "Mr. Raju Biswas - Leading Supplier of Premium Rice",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "180",
    responseRate: "88%",
    whatsappConfirmed: false,
  },
  {
    _id: "mohamed_javith_aj_traders",
    name: "Mohamed Javith",
    email: "8012807757@gmail.com",
    password: "8012807757",
    mobileNumber: "8012807757",
    companyName: "AJ TRADERS",
    statename: "Tamil Nadu",
    cityname: "Tiruchirappalli",
    productOrService: "Rice",
    imgSrc: "/assets/rice33.jpg",
    mainProducts: "Rice",
    altText: "AJ TRADERS - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Tiruchirappalli, Tamil Nadu",
    tooltipText: "Mohamed Javith - Leading Supplier of Premium Rice",
    rating: "4.5",
    ratingPercent: "89%",
    ratingsCount: "160",
    responseRate: "85%",
    whatsappConfirmed: false,
  },
  {
    _id: "satish_nanjundaiah_phala_agrotech",
    name: "Mr. Satish Nanjundaiah",
    email: "satisha.n@gmail.com",
    password: "phalaagrotech@123",
    mobileNumber: "9886692639",
    companyName: "Phala Agrotech",
    statename: "Karnataka",
    cityname: "Bangalore",
    productOrService: "Rice",
    imgSrc: "/assets/rice34.jpg",
    mainProducts: "Rice",
    altText: "Phala Agrotech - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Bangalore, Karnataka",
    tooltipText: "Mr. Satish Nanjundaiah - Leading Supplier of Premium Rice",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "175",
    responseRate: "88%",
    whatsappConfirmed: false,
  },
  {
    _id: "ms_sona_tanushka_agrotech",
    name: "Ms SONA",
    email: "tanushkaagrotech@gmail.com",
    password: "tanushkaagrotech@123",
    mobileNumber: "9372568790",
    companyName: "Tanushka Agrotech",
    statename: "Maharashtra",
    cityname: "Aurangabad",
    productOrService: "Rice",
    imgSrc: "/assets/rice35.jpg",
    mainProducts: "Rice",
    altText: "Tanushka Agrotech - Supplier of High-Quality Rice",
    years: "1 YRS",
    location: "Aurangabad, Maharashtra",
    tooltipText: "Ms SONA - Leading Supplier of Premium Rice",
    rating: "4.5",
    ratingPercent: "89%",
    ratingsCount: "150",
    responseRate: "85%",
    whatsappConfirmed: false,
  },
  {
    _id: "mr_karn_azad_karn_azad_tent_house",
    name: "Mr. Karn Azad",
    email: "karnazad.tenthouse@gmail.com",
    password: "karnazadtenthouse@123",
    mobileNumber: "7903319031",
    companyName: "Karn Azad Tent House",
    statename: "Bihar",
    cityname: "Patna",
    productOrService: "Basmati Rice",
    imgSrc: "/assets/basmati-rice3.jpg",
    mainProducts: "Basmati Rice",
    altText: "Karn Azad Tent House - Supplier of Premium Basmati Rice",
    years: "1+ YRS",
    location: "Patna, Bihar",
    tooltipText: "Mr. Karn Azad - Trusted Supplier of Basmati Rice",
    rating: "4.4",
    ratingPercent: "88%",
    ratingsCount: "120",
    responseRate: "82%",
    whatsappConfirmed: false,
  },
  {
    _id: "b1234567890tamilarasi",
    name: "Mrs. Tamilarasi",
    email: "9600768024@gmail.com",
    password: "9600768024",
    mobileNumber: "9600768024",
    companyName: "Tamilarasi",
    productOrService:
      "Rice, Tamilarasi Millet Health Mix, Tamilarasi Rathasali Rice Flour",
    imgSrc: "/assets/rice90.jpg",
    mainProducts:
      "Rice, Tamilarasi Millet Health Mix, Tamilarasi Rathasali Rice Flour",
    altText: "Tamilarasi - Rice, Millet Health Mix, Rathasali Rice Flour",
    years: "1 YRS",
    location: "Thanjavur, Tamil Nadu, India",
    tooltipText: "Tamilarasi, Thanjavur, Tamil Nadu",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "85",
    responseRate: "88%",
    whatsappConfirmed: true,
  },

  // Additional rice dealers can be added here.
];

const RiceDealers = () => {
  return (
    <div className="main-box">
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Rice Varieties</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Basmati Rice</Link>
                </li>
                <li>
                  <Link to="#">Sona Masoori Rice</Link>
                </li>
                <li>
                  <Link to="#">Jasmine Rice</Link>
                </li>
                <li>
                  <Link to="#">Brown Rice</Link>
                </li>
                <li>
                  <Link to="#">Parboiled Rice</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flt-box">
            <p className="flt-title">By Location</p>
            <div className="flt-content">
              <div className="flt-search">
                <input
                  type="text"
                  name="location_id"
                  placeholder="Search Location"
                  id="location-search-input"
                />
              </div>
              <ul className="flt-list cust-scroll" id="location-lists">
                <li>
                  <Link to="#">All India</Link>
                </li>
                <li>
                  <Link to="#">Delhi</Link>
                </li>
                <li>
                  <Link to="#">Mumbai</Link>
                </li>
                <li>
                  <Link to="#">Chennai</Link>
                </li>
                <li>
                  <Link to="#">Bengaluru</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <main>
        <section className="section">
          <p className="sect-title">Explore Rice Varieties</p>
          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rice2.jpeg"
                      width={55}
                      height={55}
                      alt="Basmati Rice"
                    />
                  </figure>
                  <p className="title">Basmati Rice</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rice3.jpeg"
                      width={55}
                      height={55}
                      alt="Sona Masoori Rice"
                    />
                  </figure>
                  <p className="title">Sona Masoori Rice</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rice4.jpeg"
                      width={55}
                      height={55}
                      alt="Jasmine Rice"
                    />
                  </figure>
                  <p className="title">Jasmine Rice</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rice5.jpeg"
                      width={55}
                      height={55}
                      alt="Brown Rice"
                    />
                  </figure>
                  <p className="title">Brown Rice</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rice1.jpeg"
                      width={55}
                      height={55}
                      alt="Parboiled Rice"
                    />
                  </figure>
                  <p className="title">Parboiled Rice</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <ul className="classfied-wrap">
          {riceDealersData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to={item.path}>
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div
                        className="ca-box modal-btn"
                        data-modal="motc"
                        data-src={item.trustCertificateUrl}
                      >
                        <p>
                          <i className="l3icon motc-icon" />
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default RiceDealers;
