import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    name: "Vicky",
    email: "vicky123@gmail.com",
    mobileNumber: "7045965003",
    companyName: "Jay Dev Garments",
    productOrService: "Fabric",
    imgSrc: "/assets/cotton-balls.jpg", // Provide the correct image path
    altText: "Fabric - Jay Dev Garments",
    mainProducts: "Cotton Fabric, Silk Fabric, Linen Fabric, Polyester Fabric",
    years: "1 YRS",
    location: "Mumbai, Maharashtra, India",
    tooltipText: "456 Fabric Street, Textile Area, Mumbai, Maharashtra, India",
    rating: "4.9",
    ratingPercent: "98%",
    ratingsCount: "80",
    responseRate: "97%",
  },
  {
    name: "Sahil Tyagi",
    email: "SahilTyagi12@gmail.com",
    mobileNumber: "8527469908",
    companyName: "Credo Exports",
    productOrService: "Fabric",
    imgSrc: "/assets/cotton-balls2.jpg", // Provide the correct image path
    altText: "Fabric - Credo Exports",
    mainProducts: "Cotton Fabric, Silk Fabric, Linen Fabric, Polyester Fabric",
    years: "1 YRS",
    location: "New Delhi India",
    tooltipText: "456 Fabric Street, Textile Area, Mumbai, Maharashtra, India",
    rating: "4.9",
    ratingPercent: "98%",
    ratingsCount: "90",
    responseRate: "97%",
  },
  {
    name: "Tijeshwar",
    email: "tijeshwar@gmail.com",
    password: "8375000867",
    mobileNumber: "9501021426",
    companyName: "Tij Textile",
    productOrService: "Fabric",
    imgSrc: "/assets/cotton-balls5.jpg",
    mainProducts: "Fabric, Textiles, Clothing Materials",
    altText: "Tij Textile - Fabric",
    years: "1 YRS",
    location: "Ludhiana, Punjab, India",
    tooltipText: "Tij Textile, Ludhiana",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "150",
    responseRate: "88%",
  },
  {
    _id: "sashtika-coirs-karur",
    name: "Ramesh",
    email: "sashtikaacoirs@gmail.com",
    password: "7868878000",
    mobileNumber: "7868878000",
    companyName: "sashtika cottons",
    productOrService: "coir",
    imgSrc: "/assets/cotton-balls3.jpg",
    mainProducts: "cotton",
    altText: "sashtika coirs - Coir Products",
    years: "1 YRS",
    location: "Karur, Tamil Nadu, India",
    tooltipText: "sashtika coirs, Coir, Karur",
    rating: "4.4",
    ratingPercent: "88%",
    ratingsCount: "25",
    responseRate: "85%",
    whatsappConfirmed: true,
  },

  // Add more items if needed
];

const WhiteCottonBales = () => {
  return (
    <>
      <div className="main-box">
        <aside>
          <div className="flt-box-wrap">
            <div className="flt-box mb-0 flt-head">Filters By</div>
            <div className="flt-box bdrt-0">
              <p className="flt-title">Related Categories</p>
              <div className="flt-content">
                <ul className="flt-list cust-scroll">
                  <li>
                    <Link to="#">Cotton Fabric</Link>
                  </li>
                  <li>
                    <Link to="#">Silk Fabric</Link>
                  </li>
                  <li>
                    <Link to="#">Linen Fabric</Link>
                  </li>
                  <li>
                    <Link to="#">Polyester Fabric</Link>
                  </li>
                  <li>
                    <Link to="#">Wool Fabric</Link>
                  </li>
                  <li>
                    <Link to="#">Rayon Fabric</Link>
                  </li>
                  <li>
                    <Link to="#">Denim Fabric</Link>
                  </li>
                  <li>
                    <Link to="#">Knitted Fabric</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flt-box">
              <p className="flt-title">By State</p>
              <div className="flt-content">
                <div className="flt-search">
                  <input
                    type="text"
                    name="state_id"
                    placeholder="Search State"
                    id="state-search-input"
                  />
                </div>
                <ul className="flt-list cust-scroll" id="state-lists">
                  <li>
                    <Link to="#">All India</Link>
                  </li>
                  <li>
                    <Link to="#">Maharashtra</Link>
                  </li>
                  <li>
                    <Link to="#">Gujarat</Link>
                  </li>
                  <li>
                    <Link to="#">Rajasthan</Link>
                  </li>
                  <li>
                    <Link to="#">Tamil Nadu</Link>
                  </li>
                  <li>
                    <Link to="#">Uttar Pradesh</Link>
                  </li>
                  <li>
                    <Link to="#">West Bengal</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <main>
          <section className="section">
            <p className="sect-title">Explore by Categories</p>
            <div className="horprd expcatg" id="expcatg">
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/fab1.jpg"
                        width={55}
                        height={55}
                        alt="Cotton Fabric"
                      />
                    </figure>
                    <p className="title">Cotton Fabric</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/fab2.jpg"
                        width={55}
                        height={55}
                        alt="Silk Fabric"
                      />
                    </figure>
                    <p className="title">Silk Fabric</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/fab3.jpg"
                        width={55}
                        height={55}
                        alt="Linen Fabric"
                      />
                    </figure>
                    <p className="title">Linen Fabric</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/fab4.jpg"
                        width={55}
                        height={55}
                        alt="Polyester Fabric"
                      />
                    </figure>
                    <p className="title">Polyester Fabric</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/fab5.jpg"
                        width={55}
                        height={55}
                        alt="Wool Fabric"
                      />
                    </figure>
                    <p className="title">Wool Fabric</p>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <ul className="classfied-wrap">
            {classifiedData.map((item, index) => (
              <li key={index}>
                <div className="classified">
                  <div className="prd-info">
                    <div className="prd-box">
                      <img
                        src={item.imgSrc}
                        alt={item.altText}
                        width={250}
                        height={250}
                      />
                    </div>
                  </div>
                  <div className="cinfo">
                    <div className="cbox">
                      <figure>
                        <span className="cmp-year">{item.years}</span>
                      </figure>
                      <div className="cboxr">
                        <Link to="#" target="_blank">
                          <h4 className="title">{item.companyName}</h4>
                        </Link>
                        <p className="cloc tooltip ellipsis">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi-location"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                          </svg>
                          {item.location}
                          <span className="tooltiptext">
                            {item.tooltipText}
                          </span>
                        </p>
                        <div className="rating-wrap">
                          <span className="rtbox">{item.rating}</span>
                          <span
                            className="crate"
                            style={{ "--_score": item.ratingPercent }}
                          />
                          <span className="rate-text">
                            {item.ratingsCount} Ratings
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="caddit">
                      <div className="item">
                        <div
                          className="ca-box modal-btn"
                          data-modal="motc"
                          data-src={item.trustCertificateUrl}
                        >
                          <p>
                            <i className="l3icon motc-icon" />
                          </p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="ca-box">
                          <p>
                            <i className="l3icon resp-icon" />
                          </p>
                          <p>
                            <span>Response Rate</span>{" "}
                            <b>{item.responseRate}</b>
                          </p>
                        </div>
                        <p>
                          <span>Main Products</span> <b>{item.mainProducts}</b>
                        </p>
                      </div>
                    </div>
                    <div className="caction">
                      <Link to={"/register-buyer"}>
                        <p>Contact Supplier</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </main>
      </div>
      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          White Cotton Bales | GlobalB2BMart
        </h2>

        <h3 class="section-heading">
          {" "}
          Best Quality White Cotton Bales in India
        </h3>
        <p class="section-content">
          GlobalB2BMart is a reliable platform that connects buyers to reliable
          suppliers when it comes to purchase the
          <b> best-quality white cotton bales in India. </b>Our white cotton
          Bales are highly demanded in a number of sectors, including textiles,
          medicinal uses, and industrial production. We ensure that every cotton
          bale is subjected to high quality standards, with contributions
          exceeding purity and softness, making it ideal for fabric production,
          purity products, and other commercial uses.
        </p>
        <p class="section-content">
          Cotton has been a keystone of the textile industry for centuries,
          valued for its longevity, breathability, and versatility. In
          particular, <b>white cotton bales</b> are of vital importance for the
          creation of superior narratives and fabrics. As companies try to
          produce superior garments, home textiles, and industrial fabrics,
          demand for premium cotton continues to grow. Together with
          GlobalB2BMart, we are proud to be offering cotton bales that meet
          international standards, ensuring that producers accept all necessary
          quality elements for their production needs.
        </p>
        <p class="section-content">
          {" "}
          We are committed to every single tool so that every single white
          cotton bale undergoes strong working and quality control before it
          arrives to our buyers. We realize that specific sectors require
          specific requirements, such as if we provide custom solutions to meet
          the various demands of textile producers, medical product
          manufacturers, and industrial users. Whether or not you require cotton
          Bales for clothing, surgical dressings, or any other kind of
          insulation, our platform will open the door to a vast network of
          verified suppliers offering the highest quality merchandise at the
          most competitive prices.
        </p>
        <p class="section-content">
          In addition, the use of <b>white cotton bales</b> produced by
          GlobalB2BMart ensures not only excellence but also sustainability.
          Cotton is a biodegradable and renewable resource, which makes it a
          green choice for companies seeking to reduce their environmental
          impact. By connecting with reliable suppliers via our platform,
          companies can defend a steady supply of premium white cotton bales
          while supporting long-term development processes. We are attempting to
          establish a market leader in the Republic of India and beyond for
          superior white cotton bales through easy transaction methods, seasonal
          delivery, and business expertise.
        </p>
        <p class="section-heading">
          <strong>White Cotton Bales Suppliers in India</strong>
        </p>
        <p class="section-content">
          We are proud to be a provider of high-quality white cotton bales
          coming directly from the farms across the country, and we are standing
          as one of the major <b> white cotton bales suppliers in India.</b>
          Cotton is a crucial natural material for the textile and clothing
          industry, which plays an essential role in the manufacture of
          high-quality garments, fabrics, and narratives. Our cotton bales are
          carefully processed to ensure their purity, robustness, and longevity
          so that they meet the highest business standards.
        </p>
        <p class="section-content">
          We're aware of the importance of excellence and consistency in the
          textile sector, which is why we're working closely with a reliable
          supplier of cotton so that we can manufacture products that meet your
          precise specifications. Our platform connects you to verified
          suppliers, thereby facilitating efficient, clear, and cost-effective
          procurement.
        </p>
        <p class="section-content">
          Our supply chain is designed to provide businesses of all sizes with a
          flexible purchasing option, no matter whether you require a small
          shipment or a bulk order. Our seamless exchanges, timely delivery, and
          competitive prices enable you to simplify your source procedure,
          guaranteeing the highest quality of cotton available on the exchange.
        </p>
        <p class="section-heading">
          <strong>Organic White Cotton Bales Exporters</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart will be your reliable supplier of premium ecologically
          friendly cotton for companies aiming to export organic white cotton
          Bales. As <b>Organic White Cotton Bales Exporters,</b> GlobalB2BMart
          ensures sustainable sourcing using renewable farming methods, free of
          harmful pesticides, man-made fertilizers, and harmful chemicals.
          Organic cotton is a safe and environmentally friendly choice for
          several sectors.
        </p>
        <p class="section-content">
          In textiles, healthcare, and industry, where purity and longevity are
          of paramount importance, organic white cotton is of high value. They
          are very familiar with environment-friendly fabrics, baby clothes,
          medical cotton, and durable industrial products in the manufacture of
          environment-friendly products. As the international requirement for
          organic fabrics grows, companies are continuously moving in the
          direction of carefully selecting elements to meet consumer
          expectations for honorable and green products.
        </p>
        <p class="section-content">
          Apart from GlobalB2BMart, we assure you that our organic cotton bales
          come from certified farms that meet international organic farming
          standards. Our deliberately selected exporters supply superior,
          contamination-free cotton Bales, which set industry benchmarks for
          fiber strength, consistency, and longevity.
        </p>
        <p class="section-content">
          Our platform connects buyers from all over the world to reliable and
          reputed suppliers, facilitating smooth and without any problem trade
          for organizations of all sizes. We support streamlining the
          procurement process by providing aggressive prices, effective
          coordination, and timely delivery, whether you require a small
          shipment or a majority order.
        </p>
        <p class="section-content">
          You get access to a wide network of reliable suppliers who promise
          quality, longevity, and fair trade practices when you choose
          GlobalB2BMart. Get in touch with us today and plant perfectly good
          organic <b>white cotton bales </b>to support your business in an
          eco-friendly way!
        </p>
        <p class="section-heading">
          <strong>Wholesale white Cotton Bales at Best Price </strong>
        </p>
        <p class="section-content">
          GlobalB2BMart offers{" "}
          <b>wholesale white cotton bales at best price.</b> With competitive
          pricing and cost-effective solutions, bulk buying further enhances
          cost reduction, particularly in the textiles, healthcare, and
          commercial manufacturing sectors.
        </p>
        <p class="section-content">
          By connecting with trustworthy suppliers, we provide a platform for
          buyers to compare financial values, evaluate standards, and strengthen
          the highest offer without making compromises on criteria. Why?
          High-quality, long-lasting, and pure cotton Bales meet industry
          standards by being manufactured in partnership with reliable
          manufacturers. Our company is committed to this high level of
          excellence.
        </p>
        <p class="section-content">
          The key ingredients of our approach are our unwavering commitment to
          ensuring efficient procurement, timely delivery, and transparent
          transactions. We prioritize reliability over effectiveness and
          customer satisfaction. If you're a healthcare fabric manufacturer,
          distributor, or buyer looking for top-quality white cotton Bales at
          wholesale prices, GlobalB2BMart is the ideal choice.
        </p>
        <p class="section-heading">
          <strong>Applications of White Cotton Bales</strong>
        </p>
        <p class="section-content">
          The availability and affordability of <b>white cotton Bales</b> make
          them highly sought-after and versatile in various industries. Among
          the main objectives are.
        </p>
        <p class="section-content">
          <b>Textiles:</b> Created to manufacture yarns, fabrics, and garments.
        </p>
        <p class="section-content">
          <b>Healthcare Sector:</b> Needs to manufacture surgical cotton,
          bandages, and other hygiene products.
        </p>
        <p class="section-content">
          <b>Industrial uses:</b> used in padding, insulation, and high-quality
          cotton materials.
        </p>
        <p class="section-content">
          Recommended for environment-friendly products. Sustainable Products:.
        </p>
        <p class="section-content">Why Choose GlobalB2BMart? </p>
        <p class="section-content">
          The B2B marketplace GlobalB2BMart is renowned for its ability to
          facilitate trade and expand business opportunities. That’s why we’re
          better...
        </p>
        <p class="section-content">
          We guarantee that the white cotton bales we produce meet global
          quality standards.{" "}
        </p>
        <p class="section-content">
          Authentic Suppliers: We connect you with authentic exporters and
          suppliers...
        </p>
        <p class="section-content">
          We offer competitive pricing for wholesale and bulk purchases. We make
          the purchase process easy and Effortless
        </p>
        <p class="section-heading">
          <strong>summary</strong>
        </p>
        <p class="section-content">
          GlobalB2Bmart is a reliable B2B platform that bridges buyers with
          quality <b>white cotton bales suppliers in India. </b>Our cotton bales
          find extensive use in textiles, medical, and industrial uses, with
          purity, durability, and sustainability guaranteed. We provide
          top-class organic and regular cotton from certified farms based on
          international standards of quality. Our platform makes procurement
          easy through authenticated suppliers, reasonable prices, and timely
          delivery. For clothing manufacturing, medical applications, or
          industrial usages, we ensure a flawless sourcing experience. Opt for
          GlobalB2BMart for a guaranteed trade experience, equitable rates, and
          highest-quality cotton bales that serve to promote ecologically and
          socially responsible business policies.
        </p>
      </div>
    </>
  );
};

export default WhiteCottonBales;
