import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../FreeCataloges.jsx/FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Black Tea", image: "./assets/tea1.jpeg" },
  { id: 3, name: "Green Tea", image: "./assets/tea2.jpeg" },
  { id: 5, name: "Herbal Tea", image: "./assets/tea3.jpeg" },
  { id: 6, name: "Assam Tea", image: "./assets/tea4.jpeg" },
  { id: 1, name: "Black Tea", image: "./assets/tea1.jpeg" },
  { id: 3, name: "Green Tea", image: "./assets/tea2.jpeg" },
  { id: 5, name: "Herbal Tea", image: "./assets/tea3.jpeg" },
  { id: 6, name: "Assam Tea", image: "./assets/tea4.jpeg" },
];

const PragotiyaTea = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the Join MD at Pragjyotish Tea Company</h1>
              Tea is one of the most popular and widely consumed beverages in
              the world. Originating from China over 5,000 years ago, tea has
              become an essential part of various cultures, from the British
              afternoon tea tradition to the Japanese tea ceremony. It is made
              by steeping the leaves of the Camellia sinensis plant in hot
              water, resulting in a flavorful and aromatic drink.
              <h2>We Deals in All kind of Tea</h2>
              <p>
                There are several types of tea, including green, black, white,
                oolong, and herbal teas. Green tea is known for its fresh,
                grassy taste and high antioxidant content, while black tea has a
                stronger, bold flavor and is commonly used in blends like Earl
                Grey and Masala Chai. Oolong tea falls between green and black
                tea in terms of oxidation, offering a rich and complex taste.
                White tea is the least processed, providing a delicate flavor
                and numerous health benefits. Herbal teas, such as chamomile and
                peppermint, are caffeine-free and made from dried herbs,
                flowers, or fruits.
              </p>
              <p>
                Tea offers numerous health benefits. It is rich in antioxidants,
                which help fight free radicals and reduce the risk of chronic
                diseases. Green tea, in particular, is linked to improved brain
                function, weight loss, and a lower risk of heart disease. Black
                tea is known for promoting heart health and boosting immunity.
                Herbal teas can aid digestion, reduce stress, and improve sleep
                quality.
              </p>
              <p>
                Beyond its health benefits, tea plays a crucial role in social
                gatherings and traditions worldwide. Whether it is enjoyed in a
                casual setting or as part of a formal ceremony, tea brings
                people together. Its soothing nature and diverse flavors make it
                a versatile and cherished beverage enjoyed by millions every
                day.
              </p>
              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}
              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/tea1.jpeg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Pragjyotish Premium Assam Tea</h3>
                    </div>

                    <ul className="attributes">
                      <li>
                        <span>Brand:</span> Pragjyotish Tea
                      </li>
                      <li>
                        <span>Product:</span> Premium Assam Tea – Rich in flavor
                        and aroma
                      </li>
                      <li>
                        <span>Type:</span> Handpicked, high-grade Assam tea
                        leaves with a bold and malty taste
                      </li>
                      <li>
                        <span>Origin:</span> Sourced from the lush tea gardens
                        of Assam, India, ensuring authenticity and superior
                        quality
                      </li>
                      <li>
                        <span>Aroma:</span> Strong, invigorating fragrance with
                        earthy undertones
                      </li>
                      <li>
                        <span>Flavor Profile:</span> Robust, full-bodied taste
                        with a perfect balance of briskness and sweetness
                      </li>
                      <li>
                        <span>Health Benefits:</span> Rich in antioxidants,
                        promotes digestion, boosts energy, and supports heart
                        health
                      </li>
                      <li>
                        <span>Packaging:</span> Available in 250g, 500g, and 1kg
                        airtight packs to preserve freshness and aroma
                      </li>
                      <li>
                        <span>Brewing Recommendations:</span> Best enjoyed with
                        or without milk, brew for 3-5 minutes for optimal taste
                      </li>
                      <li>
                        <span>Usage:</span> Ideal for morning tea, evening
                        refreshment, or as a base for masala chai
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              JD
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  Join MD at Pragjyotish Tea{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Guwahati, India
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default PragotiyaTea;
