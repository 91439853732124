import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    _id: "gehans-kitchen-pune",
    name: "Ghina Ji",
    email: "gkspicekart@gmail.com",
    password: "12345678",
    mobileNumber: "+91 97656 08088",
    companyName: "Gehan's Kitchen",
    productOrService: "Spices",
    imgSrc: "/assets/ginger-img.jpg",
    mainProducts: "Spices",
    altText: "Gehan's Kitchen - Spices",
    years: "1 YRS",
    location: "Pune, India",
    tooltipText: "Gehan's Kitchen, Spices, Pune",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "45",
    responseRate: "85%",
    path: "/gehena-kitchen",
  },

  {
    name: "Mr. Amit",
    email: "abc@gmail.com",
    password: "amit@123",
    mobileNumber: "9962433456",
    companyName: "Aroma Spice Industries",
    productOrService: "Spices",
    imgSrc: "/assets/spi.jpg", // Provide the correct image path
    altText: "Spices - Aroma Spice Industries",
    mainProducts: "Turmeric, Cardamom, Cinnamon",
    years: "1 YRS",
    location: "Nagpur, India",
    tooltipText: "789 Spice Lane, Industrial Park, Nagpur, India",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "85",
    responseRate: "94%",
  },
  {
    _id: "u7v8w9x0y1z2a3b4c5d6e7f8", // Random unique ID
    name: "Akash Kandula",
    email: "bagyesh123456789@gmail.com",
    mobileNumber: "8919156078",
    companyName: "Akash Enterprise",
    productOrService: "Spices",
    imgSrc: "/assets/spices11.jpeg", // Example image path
    mainProducts: "Whole Spices, Ground Spices, Spice Blends, Organic Spices",
    altText: "Spices - Akash Enterprise",
    years: "1 YRS",
    location: "Hyderabad, India",
    tooltipText: "12 Spice Lane, Hyderabad, India",
    rating: "4.5",
    ratingPercent: "91%",
    ratingsCount: "55",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    name: "Surya Parkash",
    email: "9789499491@gmail.com",
    password: "9789499491",
    mobileNumber: "9789499491",
    companyName: "Amutham Masala",
    productOrService: "Spices",
    imgSrc: "/assets/spice99.jpg",
    mainProducts: "Spices, Masalas, Organic Spices",
    altText: "Amutham Masala - Spices",
    years: "1 YRS",
    location: "Coimbatore, Tamil Nadu, India",
    tooltipText: "Amutham Masala, Coimbatore",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "85",
    responseRate: "88%",
    whatsappConfirmed: true,
  },

  {
    name: "Mr. Rishab",
    email: "ambicaspicesindustries@gmail.com",
    password: "rishab1234",
    mobileNumber: "9024690134",
    companyName: "AMBICA SPICES INDUSTRIES",
    productOrService: "SPICES",
    imgSrc: "/assets/spices4.jpg", // Provide the correct image path
    altText: "Spices - AMBICA SPICES INDUSTRIES",
    mainProducts: "Turmeric, Red Chili, Black Pepper",
    years: "1 YRS",
    location: "Nagpur, India",
    tooltipText: "789 Spice Lane, Agro Industrial Park, Nagpur, India",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "85",
    responseRate: "88%",
  },
  {
    name: "Janardhan Walavalkar",
    email: "info@walavalkarmasale.com",
    mobileNumber: "9004144001",
    companyName: "Walavalkar Masale",
    productOrService: "Spices",
    imgSrc: "/assets/spices6.jpeg", // Provide the correct image path
    mainProducts: "Turmeric Powder, Chilli Powder, Garam Masala, Cumin Seeds",
    altText: "Spices - Walavalkar Masale",
    years: "1 YRS",
    location: "Mumbai, India",
    tooltipText: "Location details not provided",
    rating: "85%",
    ratingPercent: "90%",
    ratingsCount: "50",
    responseRate: "88%",
  },

  {
    name: "Mr. Aditya Sonpawle",
    email: "foodspvtltdd@gmail.com",
    password: "aditya@123",
    mobileNumber: "7218897080",
    companyName: "AAUDUMBAR AGRO PVT LTD",
    productOrService: "Spices & food product",
    imgSrc: "/assets/spices2.jpg", // Provide the correct image path
    altText: "Spices - AAUDUMBAR AGRO PVT LTD",
    mainProducts: "Turmeric, Red Chili, Black Pepper",
    years: "1 YRS",
    location: "Nagpur, India",
    tooltipText: "789 Spice Lane, Agro Industrial Park, Nagpur, India",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "85",
    responseRate: "88%",
  },
  {
    name: "rishab",
    email: "ambicaspicesindustries@gmail.com",
    mobileNumber: "9024690134",
    companyName: "AMBICA SPICES INDUSTRIES",
    productOrService: "SPICES",
    imgSrc: "/assets/spices.jpg",
    altText: "Spices - Ambica Spices Industries",
    mainProducts: "Turmeric, Cumin, Cardamom",
    years: "1 YRS",
    location: "Mumbai, India",
    tooltipText: "456 Spice Market, Masala Lane, Mumbai, India",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "60",
    responseRate: "85%",
  },
  {
    _id: "e1234567890f012347",
    name: "Jesmon A V",
    email: "Jesmon1234@gmail.com",
    password: "Jesmon@1234",
    mobileNumber: "9815764526",
    companyName: "VKV Industries",
    productOrService: "Spices",
    imgSrc: "/assets/spices7.jpeg",
    mainProducts: "Turmeric, Pepper, Cardamom",
    altText: "Spices - VKV Industries",
    years: "1 YRS",
    location: "Kochi, India",
    tooltipText: "Sector 4, Spice Market, Kochi, India",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "70",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "m1234567890n012347",
    name: "DY Food Exim",
    email: "info@dyfoodexim.com",
    password: "DY FOOD EXIM",
    mobileNumber: "9979479787",
    companyName: "DY Food Exim",
    productOrService: "Spices",
    imgSrc: "/assets/spices9.jpeg",
    mainProducts: "Turmeric, Black Pepper, Cumin",
    altText: "Spices - DY Food Exim",
    years: "1 YRS",
    location: "Delhi, India",
    tooltipText: "Sector 4, Spice Market, Delhi, India",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "70",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "z8y7x6w5v4u3t2s1r0q9p8o7", // Random unique ID
    name: "K Arjun Raj",
    email: "Arjun1234@gamil.com",
    mobileNumber: "9995907083",
    companyName: "Cardamom Manufacture",
    productOrService: "Cardamom",
    imgSrc: "/assets/cardomn.jpg", // Example image path
    mainProducts:
      "Green Cardamom, Black Cardamom, Ground Cardamom, Cardamom Pods",
    altText: "Cardamom - Cardamom Manufacture",
    years: "1 YRS",
    location: "Kerala, India",
    tooltipText: "456 Spice Lane, Kerala, India",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "85",
    responseRate: "97%",
    whatsappConfirmed: true,
  },
  {
    _id: "a1234567890wxyz5678",
    name: "Anil Bisee",
    email: "abc@gmail.com",
    password: "anil@123",
    mobileNumber: "8827722786",
    companyName: "AB International",
    productOrService: "Spices",
    imgSrc: "/assets/spices10.jpeg", // Placeholder for image path
    mainProducts: "Spices, Herbs, Seasonings",
    altText: "Spices - AB International",
    years: "1 YRS", // Placeholder for years of experience
    location: "Mumbai, India", // Placeholder for location
    tooltipText: "AB International, Mumbai, India",
    rating: "4.5", // Placeholder for rating
    ratingPercent: "90%", // Placeholder for rating percentage
    ratingsCount: "120", // Placeholder for ratings count
    responseRate: "85%", // Placeholder for response rate
    whatsappConfirmed: true,
  },
  {
    name: "Sushil Kumar",
    email: "abc@gmail.com",
    password: "sushil@123",
    mobileNumber: "8975550777",
    companyName: "Som Agencies",
    productOrService: "Spices",
    imgSrc: "/assets/spices12.jpg",
    mainProducts: "Spices",
    altText: "Som Agencies - Spices",
    years: "1 YRS",
    location: "Dhulia, Maharashtra, India",
    tooltipText: "Som Agencies, Spices, Dhulia",
    rating: "4.2",
    ratingPercent: "84%",
    ratingsCount: "30",
    responseRate: "87%",
    whatsappConfirmed: false,
  },
  {
    _id: "66fe73ab5ece709722c7c75c",
    name: "Krm Aajif",
    email: "kmohamedasif1984786@gmail.com",
    password: "8072022398",
    mobileNumber: "8072022398",
    companyName: "Jazar marketing",
    productOrService: "Elaichi",
    imgSrc: "/assets/elechi.jpg",
    mainProducts: "Elaichi",
    altText: "Jazar marketing - Elaichi",
    years: "1 YRS",
    location: "Trichirappalli, Tamil Nadu, India",
    tooltipText: "Jazar marketing, Elaichi, Trichirappalli",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "30",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "surya-amutham-masala-coimbatore",
    name: "surya",
    email: "surya@gmail.com",
    password: "9789499491",
    mobileNumber: "9789499491",
    companyName: "Amutham Masala",
    productOrService: "spice and masala powders",
    imgSrc: "/assets/masala1.jpg",
    mainProducts: "spice and masala powders",
    altText: "Amutham Masala - spice and masala powders",
    years: "1 YRS",
    location: "Coimbatore, Tamil Nadu, India",
    tooltipText: "Amutham Masala, spice and masala powders, Coimbatore",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "30",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "mohan-mrm-trading-madura",
    name: "MOHAN",
    email: "8946008717@gmail.com",
    password: "8946008717",
    mobileNumber: "8946008717",
    companyName: "MRM TRADING",
    productOrService: "Spices",
    imgSrc: "/assets/spices13.jpg",
    mainProducts: "Spices",
    altText: "MRM TRADING - Spices",
    years: "1 YRS",
    location: "Madura, Tamil Nadu, India",
    tooltipText: "MRM TRADING, Spices, Madura",
    rating: "4.7",
    ratingPercent: "95%",
    ratingsCount: "45",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "prince-spices-khagaria",
    name: "Babban Singh",
    email: "BabbanSingh123@gmail.com",
    password: "Babban Singh",
    mobileNumber: "6201198448",
    companyName: "PRINCE SPICES",
    statename: "Bihar",
    cityname: "Khagaria, Bihar",
    productOrService: "Spices",
    imgSrc: "/assets/prince-spices.jpg",
    mainProducts: "Ground Spices, Whole Spices, Spice Blends",
    altText: "PRINCE SPICES - Spices Manufacturer",
    years: "1 YRS",
    location: "Khagaria, Bihar, India",
    tooltipText: "PRINCE SPICES - Authentic Spice Producer",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "200",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    _id: "sandeep-delhi",
    name: "Sandeep",
    email: "sandeep@gmail.com",
    password: "12345678",
    mobileNumber: "9311758847",
    companyName: "Sandeep Spices",
    statename: "Delhi",
    cityname: "Delhi",
    productOrService: "Spices",
    imgSrc: "/assets/spices15.jpg",
    mainProducts: "Premium Spices, Herbal Seasonings",
    altText: "Sandeep - Quality Spice Supplier in Delhi",
    years: "1 YRS",
    location: "Delhi, India",
    tooltipText: "Sandeep - Your Trusted Supplier of Fresh Spices",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "210",
    responseRate: "88%",
    whatsappConfirmed: true,
  },

  {
    _id: "sandeep-delhi",
    name: "Sandeep",
    email: "sandeep@gmail.com",
    password: "12345678",
    mobileNumber: "9311758847",
    companyName: "Sandeep",
    statename: "Delhi",
    cityname: "Delhi",
    productOrService: "Spices",
    imgSrc: "/assets/sices20.jpg",
    mainProducts: "High-Quality Spices, Seasoning Blends",
    altText: "Sandeep - Premium Spice Supplier in Delhi",
    years: "1 YRS",
    location: "Delhi, India",
    tooltipText: "Sandeep - Trusted Spices Supplier",
    rating: "4.5",
    ratingPercent: "91%",
    ratingsCount: "180",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "indo-natural-chennai",
    name: "Ravimenon Goplkrishnan",
    email: "9995153138@gmail.com",
    password: "9995153138",
    mobileNumber: "9995153138",
    companyName: "INDO Natural",
    statename: "Tamil Nadu",
    cityname: "Chennai",
    productOrService: "Spices",
    imgSrc: "/assets/spices21.jpg",
    mainProducts: "High-Quality Spices",
    altText: "INDO Natural - Premium Spices in Chennai",
    years: "1 YRS",
    location: "Chennai, Tamil Nadu, India",
    tooltipText: "INDO Natural - Leading Spices Supplier",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "150",
    responseRate: "80%",
    whatsappConfirmed: true,
  },
  {
    _id: "vcs-prema-enterprises-chennai",
    name: "Vignesh",
    email: "8778792896@gmail.com",
    password: "8778792896",
    mobileNumber: "8778792896",
    companyName: "VCS PREMA ENTERPRISES",
    statename: "Tamil Nadu",
    cityname: "Chennai",
    productOrService: "Spices",
    imgSrc: "/assets/spices16.jpg",
    mainProducts: "High-Quality Spices",
    altText: "VCS PREMA ENTERPRISES - Premium Spices in Chennai",
    years: "1 YRS",
    location: "Chennai, Tamil Nadu, India",
    tooltipText: "VCS PREMA ENTERPRISES - Trusted Spices Supplier",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "140",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "hmv-enterprises-pune",
    name: "Tofiq Ali",
    email: "TofiqAli123@gmail.com",
    password: "Tofiq Ali",
    mobileNumber: "7828912725",
    companyName: "HMV Enterprises",
    statename: "Maharashtra",
    cityname: "Pune",
    productOrService: "Spicec",
    imgSrc: "/assets/spices17.jpg",
    mainProducts: "Spices and Seasonings",
    altText: "HMV Enterprises - Leading Spice Supplier in Pune",
    years: "1 YRS",
    location: "Pune, Maharashtra, India",
    tooltipText: "HMV Enterprises - Trusted Spice Supplier",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "200",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "s345678901abcdef234582", // Random unique ID
    name: "Hardesh Singh",
    email: "HardeshSingh123@gmail.com",
    password: "Hardesh Singh",
    mobileNumber: "8218140542",
    companyName: "SHREE SHIVAY",
    productOrService: "Spices",
    imgSrc: "/assets/spices18.jpg", // Example image path
    mainProducts: "Red Chilli, Turmeric, Coriander",
    altText: "Spice Supplier - SHREE SHIVAY",
    years: "1 YRS", // Replace X with the actual years of experience
    location: "Bareilly, Uttar Pradesh, India",
    tooltipText: "Bareilly, Uttar Pradesh, India",
    rating: "4.4",
    ratingPercent: "88%",
    ratingsCount: "40",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "s345678901abcdef234584", // Random unique ID
    name: "Amal Biju",
    email: "amal@gmail.com",
    password: "12345678",
    mobileNumber: "7593998523",
    companyName: "Spices Gram",
    productOrService: "Spices",
    imgSrc: "/assets/spices8.jpg", // Example image path
    mainProducts: "Chili Powder, Turmeric, Cumin",
    altText: "Spice Supplier - Spices Gram",
    years: "1 YRS", // Replace X with the actual years of experience
    location: "Tamil Nadu, Tamil Nadu, India",
    tooltipText: "Tamil Nadu, Tamil Nadu, India",
    rating: "4.4",
    ratingPercent: "88%",
    ratingsCount: "40",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "mouzam-mohammed-khan",
    name: "Mouzam Mohammed Khan",
    email: "mouzzam_khan@yahoo.com",
    password: "Mouzam Mohammed Khan",
    mobileNumber: "9898904444",
    companyName: "Multi Service Trading",
    statename: "Telangana",
    cityname: "Hyderabad",
    productOrService: "Spices and Vegetable Powders",
    imgSrc: "/assets/spices90.jpg",
    mainProducts: "Spices, Vegetable Powders",
    altText: "Mouzam Mohammed Khan - Spices and Vegetable Powder Supplier",
    years: "1 YRS",
    location: "Hyderabad, Telangana",
    tooltipText:
      "Mouzam Mohammed Khan - Specialized in Spices and Vegetable Powders",
    rating: "4.2",
    ratingPercent: "80%",
    ratingsCount: "85",
    responseRate: "90%",
    whatsappConfirmed: false,
  },
  {
    _id: "dharmik-prajapati-himik-international-exports",
    name: "Mr. Dharmik Prajapati",
    email: "dharmikprajapati1210@gmail.com",
    password: "Mr. Dharmik Prajapati",
    mobileNumber: "9537449831",
    companyName: "Himik International Exports",
    statename: "Gujarat",
    cityname: "Aravli",
    productOrService: "Spices",
    imgSrc: "/assets/spices22.jpg",
    mainProducts:
      "High-Quality Spices, Organic Spices, Whole and Ground Spices",
    altText: "Mr. Dharmik Prajapati - Supplier of Spices",
    years: "1 YRS",
    location: "Aravli, Gujarat, India",
    tooltipText: "Mr. Dharmik Prajapati - Expert in Exporting Spices",
    rating: "4.8",
    ratingPercent: "95%",
    ratingsCount: "145",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "mr-br-shyam-prakash-ss-agro-tech",
    name: "Mr. B R Shyam Prakash",
    email: "ssagrotechblr@gmail.com",
    password: "Mr. B R Shyam Prakash",
    mobileNumber: "9048035660",
    companyName: "S S Agro Tech",
    statename: "Karnataka",
    cityname: "Bangalore",
    productOrService: "Spices",
    imgSrc: "/assets/spices19.jpg",
    mainProducts: "High-Quality Spices, Organic Spices, Seasoning Products",
    altText: "Mr. B R Shyam Prakash - Supplier of Spices",
    years: "1 YRS",
    location: "Bangalore, Karnataka, India",
    tooltipText: "Mr. B R Shyam Prakash - Expert in Spices and Seasoning",
    rating: "4.5",
    ratingPercent: "91%",
    ratingsCount: "89",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "atharv-pathak-hn-export-import",
    name: "Atharv Pathak",
    email: "atharvpathak@gmail.com",
    password: "",
    mobileNumber: "9511019611",
    companyName: "HN Export and Import Service",
    statename: "Uttar Pradesh",
    cityname: "Lucknow",
    productOrService: "Spices",
    imgSrc: "/assets/spices24.jpg",
    mainProducts: "Export-Quality Spices, Seasoning Products",
    altText: "Atharv Pathak - Supplier of Spices",
    years: "1 YRS",
    location: "Lucknow, Uttar Pradesh, India",
    tooltipText: "Atharv Pathak - Expert in Export-Quality Spices",
    rating: "4.4",
    ratingPercent: "89%",
    ratingsCount: "76",
    responseRate: "88%",
    whatsappConfirmed: false,
  },
  {
    _id: "lalji-bhai-vadel-vasundhra-seling-company",
    name: "Lalji Bhai Vadel",
    email: "Lsv4678@gmail.com",
    password: "lalji bhai vadel",
    mobileNumber: "9714066398",
    companyName: "VASUNDHRA SELING COMPANY",
    statename: "Gujrat",
    cityname: "Botad",
    productOrService: "Spices",
    imgSrc: "/assets/spices31.jpg",
    mainProducts: "High-Quality Spices, Indian Spices, Seasonings",
    altText: "Lalji Bhai Vadel - Supplier of Spices",
    years: "1 YRS",
    location: "Botad, Gujarat, India",
    tooltipText: "Lalji Bhai Vadel - Trusted Supplier of Spices",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "84",
    responseRate: "85%",
    whatsappConfirmed: false,
  },
  {
    _id: "tholiya-sunil-spices",
    name: "Tholiya Sunil",
    email: "tholiyasunilsunil@gmail.com",
    password: "12345678",
    mobileNumber: "8128879753",
    companyName: "Tholiya Sunil",
    statename: "Maharashtra",
    cityname: "Mumbai",
    productOrService: "Spices",
    imgSrc: "/assets/spices.jpg",
    mainProducts: "Blended Spices, Ground Spices, Whole Spices",
    altText: "Tholiya Sunil - Supplier of Premium Spices",
    years: "1 YRS",
    location: "Mumbai, Maharashtra, India",
    tooltipText: "Tholiya Sunil - Supplier of High-Quality Spices",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "85",
    responseRate: "91%",
    whatsappConfirmed: false,
  },
  {
    _id: "obaol-supreme",
    name: "Jacob Alwin Joy",
    email: "obaol.biz@gmail.com",
    password: "Obaol@2002",
    mobileNumber: "7306096941",
    companyName: "Obaol Supreme",
    statename: "Karnataka",
    cityname: "Bangalore",
    productOrService: "Spices",
    imgSrc: "/assets/spices32.jpg",
    mainProducts: "Spices",
    altText: "Obaol Supreme - Premium Spice Manufacturer",
    years: "1 YRS",
    location: "Bangalore, Karnataka, India",
    tooltipText: "Jacob Alwin Joy - Expert in High-Quality Spices",
    rating: "4.5",
    ratingPercent: "92%",
    ratingsCount: "65",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "rehoboth-natural-ingredients",
    name: "S. L. Leston Sam",
    email: "rehonaturals@gmail.com",
    password: "7339339654",
    mobileNumber: "7339339654",
    companyName: "Rehoboth Natural Ingredients",
    statename: "Tamil Nadu",
    cityname: "Coimbatore",
    productOrService: "Spices, Essential Oils, Medicinal Herbs",
    imgSrc: "/assets/spices30.jpg",
    mainProducts: "Spices, Essential Oils, Medicinal Herbs",
    altText:
      "Rehoboth Natural Ingredients - Trusted Supplier of Natural Ingredients",
    years: "1 YRS",
    location: "Coimbatore, Tamil Nadu, India",
    tooltipText:
      "S. L. Leston Sam - Expert in Natural Spices, Essential Oils, and Herbs",
    rating: "4.9",
    ratingPercent: "97%",
    ratingsCount: "150",
    responseRate: "93%",
    whatsappConfirmed: true,
  },
  {
    _id: "maha-ganapathy-traders",
    name: "Mrs. Mahalakshmi",
    email: "mahasm1920@gmail.com",
    password: "7397379277",
    mobileNumber: "7397379277",
    companyName: "MAHA GANAPATHY TRADERS",
    statename: "Tamil Nadu",
    cityname: "Chennai",
    productOrService: "Whole Spices, Hotel Textiles Products",
    imgSrc: "/assets/wholesale-spices.jpg",
    mainProducts: "Whole Spices, Hotel Textiles Products",
    altText:
      "MAHA GANAPATHY TRADERS - Supplier of Whole Spices and Hotel Textiles Products",
    years: "1 YRS",
    location: "Chennai, Tamil Nadu, India",
    tooltipText:
      "Mrs. Mahalakshmi - Specialist in Whole Spices and Hotel Textiles Products",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "48",
    responseRate: "91%",
    whatsappConfirmed: true,
  },
  {
    _id: "bhagvate_exports",
    name: "Nilesh",
    email: "bhagvateexports@gmail.com",
    password: "NileshNilesh",
    mobileNumber: "8200083855",
    companyName: "BHAGVATE EXPORTS",
    statename: "Gujrat",
    cityname: "Surat",
    productOrService: "Ginger Powder",
    imgSrc: "/assets/ginger-powder1.jpg",
    mainProducts: "Ginger Powder",
    altText: "BHAGVATE EXPORTS - Supplier of Ginger Powder",
    years: "1 YRS",
    location: "Surat, Gujarat, India",
    tooltipText: "Nilesh - Trusted Supplier of Ginger Powder",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "105",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    name: "Mr. Amarnath S",
    email: "amarparadise@gmail.com",
    mobileNumber: "8148032452",
    companyName: "A1 Quality Peppers",
    productOrService: "Black Pepper, White Pepper",
    imgSrc: "/assets/pepper1.jpg",
    altText: "Black Pepper, White Pepper - A1 Quality Peppers",
    mainProducts: "Black Pepper, White Pepper",
    years: "1 YRS",
    location: "Tiruchirappalli, Tamil Nadu, India",
    tooltipText: "A1 Quality Peppers, Tiruchirappalli, Tamil Nadu, India",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "120",
    responseRate: "85%",
  },
  {
    name: "Mr. Srinivasulu P",
    email: "p.s.rimportandexporters@gmail.com",
    mobileNumber: "9551889999",
    companyName: "PSR Exporters",
    productOrService: "Dry Red Chilli, Ponni Rice",
    imgSrc: "/assets/red-chilli1.jpg",
    altText: "Dry Red Chilli, Ponni Rice - PSR Exporters",
    mainProducts: "Dry Red Chilli, Ponni Rice",
    years: "1 YRS",
    location: "Chennai, Tamil Nadu, India",
    tooltipText: "PSR Exporters, Chennai, Tamil Nadu, India",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "100",
    responseRate: "80%",
  },
  {
    _id: "mrs_heena_rathod_wholetrade_international",
    name: "Mrs. Heena Rathod",
    email: "wholetradeinternational@gmail.com",
    password: "wholetradeinternational@123",
    mobileNumber: "8849614188",
    companyName: "Wholetrade International",
    statename: "Gujarat",
    cityname: "Ahmedabad",
    productOrService: "Indian Spices",
    imgSrc: "/assets/spices33.jpg",
    mainProducts: "Indian Spices",
    altText:
      "Wholetrade International - Supplier of High-Quality Indian Spices",
    years: "1+ YRS",
    location: "Ahmedabad, Gujarat",
    tooltipText:
      "Mrs. Heena Rathod - Leading Supplier of Premium Indian Spices",
    rating: "4.5",
    ratingPercent: "89%",
    ratingsCount: "150",
    responseRate: "85%",
    whatsappConfirmed: false,
  },
  {
    _id: "heena_rathod_wholetrade_international",
    name: "Mrs. Heena Rathod",
    email: "wholetradeinternational@gmail.com",
    password: "wholetradeinternational@123",
    mobileNumber: "8849614188",
    companyName: "Wholetrade International",
    statename: "Gujarat",
    cityname: "Ahmedabad",
    productOrService: "Indian Spices",
    imgSrc: "/assets/spices26.jpg",
    mainProducts: "Turmeric, Cumin, Coriander, Red Chilli, Black Pepper",
    altText: "Wholetrade International - Supplier of Premium Indian Spices",
    years: "1+ YRS",
    location: "India, Gujarat",
    tooltipText: "Mrs. Heena Rathod - Trusted Exporter of Indian Spices",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "210",
    responseRate: "90%",
    whatsappConfirmed: false,
  },
  {
    _id: "origin_global",
    name: "Mr. Dishant Gajera",
    email: "p.s.rimportandexporters@gmail.com",
    password: "",
    mobileNumber: "9409693404",
    companyName: "PSR Exporters",
    productOrService: "Dry Red Chilli, Ponni Rice",
    imgSrc: "/assets/red-chilli2.jpg",
    mainProducts: "Spices Powder",
    altText: "Spices Powder - Origin Global",
    years: "1 YRS",
    location: "Chennai, Tamil Nadu, India",
    tooltipText: "Origin Global, Ahmedabad, Gujarat",
    rating: "4.5",
    ratingPercent: "92%",
    ratingsCount: "120",
    responseRate: "88%",
    whatsappConfirmed: false,
  },
  {
    _id: "heena_rathod_wholetrade_international",
    name: "Mrs. Heena Rathod",
    email: "wholetradeinternational@gmail.com",
    password: "wholetradeinternational@123",
    mobileNumber: "8849614188",
    companyName: "Wholetrade International",
    statename: "Gujarat",
    cityname: "Ahmedabad",
    productOrService: "Indian Spices",
    imgSrc: "/assets/spices26.jpg",
    mainProducts: "Turmeric, Cumin, Coriander, Red Chilli, Black Pepper",
    altText: "Wholetrade International - Supplier of Premium Indian Spices",
    years: "1+ YRS",
    location: "India, Gujarat",
    tooltipText: "Mrs. Heena Rathod - Trusted Exporter of Indian Spices",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "210",
    responseRate: "90%",
    whatsappConfirmed: false,
  },
  {
    _id: "origin_global",
    name: "Mr. Dishant Gajera",
    email: "originglobal@gmail.com",
    password: "",
    mobileNumber: "9409693404",
    companyName: "Origin Global",
    productOrService: "Spices Powder",
    imgSrc: "/assets/spices29.jpg",
    mainProducts: "Spices Powder",
    altText: "Spices Powder - Origin Global",
    years: "1 YRS",
    location: "Ahmedabad, Gujarat, India",
    tooltipText: "Origin Global, Ahmedabad, Gujarat",
    rating: "4.5",
    ratingPercent: "92%",
    ratingsCount: "120",
    responseRate: "88%",
    whatsappConfirmed: false,
  },
  {
    _id: "kamadhenu_food_products",
    name: "Mr. Kaluram",
    email: "9941111311@gmail.com",
    password: "9941111311",
    mobileNumber: "9941111311",
    companyName: "Kamadhenu Food Products",
    productOrService:
      "Seeds, Flours, Cooking Spices And Masala, Whole Spices, Cooking Spices",
    imgSrc: "/assets/seeds99.jpg",
    mainProducts: "Seeds, Flours, Whole Spices, Cooking Spices, Masala",
    altText: "Seeds, Flours & Spices - Kamadhenu Food Products",
    years: "1 YRS",
    location: "Chennai, Tamil Nadu, India",
    tooltipText: "Kamadhenu Food Products, Chennai, Tamil Nadu",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "180",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
  {
    _id: "deepyog_consumer_products_pvt_ltd",
    name: "Yogesh K",
    email: "6382949412@gmail.com",
    password: "6382949412",
    mobileNumber: "6382949412",
    companyName: "Deepyog Consumer Products Pvt Ltd",
    productOrService: "Coriander Powder, Turmeric Powder, Mustard Small",
    imgSrc: "/assets/coorinder-powder0.jpg",
    mainProducts: "Coriander Powder, Turmeric Powder, Mustard Small",
    altText:
      "Coriander Powder, Turmeric Powder & Mustard - Deepyog Consumer Products",
    years: " YRS",
    location: "Chennai, Tamil Nadu, India",
    tooltipText: "Deepyog Consumer Products, Chennai, Tamil Nadu",
    rating: "4.2",
    ratingPercent: "85%",
    ratingsCount: "120",
    responseRate: "80%",
    whatsappConfirmed: true,
  },
];

const SpicesFoodProducts = () => {
  return (
    <>
      <div className="main-box">
        <aside>
          <div className="flt-box-wrap">
            <div className="flt-box mb-0 flt-head">Filters By</div>
            <div className="flt-box bdrt-0">
              <p className="flt-title">Related Categories</p>
              <div className="flt-content">
                <ul className="flt-list cust-scroll">
                  <li>
                    <Link to="#">Turmeric</Link>
                  </li>
                  <li>
                    <Link to="#">Red Chili</Link>
                  </li>
                  <li>
                    <Link to="#">Black Pepper</Link>
                  </li>
                  <li>
                    <Link to="#">Cumin</Link>
                  </li>
                  <li>
                    <Link to="#">Coriander</Link>
                  </li>
                  <li>
                    <Link to="#">Cardamom</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flt-box">
              <p className="flt-title">By State</p>
              <div className="flt-content">
                <div className="flt-search">
                  <input
                    type="text"
                    name="state_id"
                    placeholder="Search State"
                    id="state-search-input"
                  />
                </div>
                <ul className="flt-list cust-scroll" id="state-lists">
                  <li>
                    <Link to="#">All India</Link>
                  </li>
                  <li>
                    <Link to="#">Maharashtra</Link>
                  </li>
                  <li>
                    <Link to="#">Tamil Nadu</Link>
                  </li>
                  <li>
                    <Link to="#">Karnataka</Link>
                  </li>
                  <li>
                    <Link to="#">Andhra Pradesh</Link>
                  </li>
                  <li>
                    <Link to="#">Gujarat</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <main>
          <section className="section">
            <p className="sect-title">Explore by Categories</p>
            <div className="horprd expcatg" id="expcatg">
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/spices.jpg"
                        width={55}
                        height={55}
                        alt="Turmeric"
                      />
                    </figure>
                    <p className="title">Turmeric</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/spices1.jpg"
                        width={55}
                        height={55}
                        alt="Red Chili"
                      />
                    </figure>
                    <p className="title">Red Chili</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/spices2.jpg"
                        width={55}
                        height={55}
                        alt="Black Pepper"
                      />
                    </figure>
                    <p className="title">Black Pepper</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/spices4.jpg"
                        width={55}
                        height={55}
                        alt="Cumin"
                      />
                    </figure>
                    <p className="title">Cumin</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/spices5.jpg"
                        width={55}
                        height={55}
                        alt="Coriander"
                      />
                    </figure>
                    <p className="title">Coriander</p>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <ul className="classfied-wrap">
            {classifiedData.map((item, index) => (
              <li key={index}>
                <div className="classified">
                  <div className="prd-info">
                    <div className="prd-box">
                      <img
                        src={item.imgSrc}
                        alt={item.altText}
                        width={250}
                        height={250}
                      />
                    </div>
                  </div>
                  <div className="cinfo">
                    <div className="cbox">
                      <figure>
                        <span className="cmp-year">{item.years}</span>
                      </figure>
                      <div className="cboxr">
                        <Link to={item.path}>
                          <h4 className="title">{item.companyName}</h4>
                        </Link>
                        <p className="cloc tooltip ellipsis">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi-location"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                          </svg>
                          {item.location}
                          <span className="tooltiptext">
                            {item.tooltipText}
                          </span>
                        </p>
                        <div className="rating-wrap">
                          <span className="rtbox">{item.rating}</span>
                          <span
                            className="crate"
                            style={{ "--_score": item.ratingPercent }}
                          />
                          <span className="rate-text">
                            {item.ratingsCount} Ratings
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="caddit">
                      <div className="item">
                        <div
                          className="ca-box modal-btn"
                          data-modal="motc"
                          data-src={item.trustCertificateUrl}
                        >
                          <p>
                            <i className="l3icon motc-icon" />
                          </p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="ca-box">
                          <p>
                            <i className="l3icon resp-icon" />
                          </p>
                          <p>
                            <span>Response Rate</span>{" "}
                            <b>{item.responseRate}</b>
                          </p>
                        </div>
                        <p>
                          <span>Main Products</span> <b>{item.mainProducts}</b>
                        </p>
                      </div>
                    </div>
                    <div className="caction">
                      <Link to={"/register-buyer"}>
                        <p>Contact Supplier</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </main>
      </div>
      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Pure Indian Spices | GlobalB2BMart
        </h2>
        {/* <p class="section-heading">
          <strong>Ladies Readymade Garments</strong>
        </p> */}
        <p class="section-content">
          <b> Pure Indian spices </b>
          have been an essential part of India's rich culinary and cultural
          heritage for centuries. These spices not only add a distinctive taste
          and vibrant color to dishes but also offer numerous health benefits,
          making them an essential ingredient in Indian households. From
          turmeric, known for its anti-inflammatory properties, to cumin and
          coriander, which aid digestion, Indian spices have been valued for
          their medicinal properties in Ayurveda for generations. They are also
          deeply rooted in religious and cultural ceremonies, where they
          symbolize purity, tradition, and prosperity.
        </p>
        <p class="section-heading">
          <strong>Why Pure Indian Spices?</strong>
        </p>
        <p class="section-content">
          One of the most important reasons <b> pure Indian spices</b> are so in
          demand is due to their medicinal and nutritional value. Turmeric, for
          instance, is full of curcumin, which is anti-inflammatory and
          antioxidant. Black pepper stimulates digestion, while cinnamon and
          cloves have antimicrobial qualities. These spices are also used
          extensively in Ayurveda, where they have been relied upon for
          centuries to promote overall health and well-being. Unlike adulterated
          or processed spices, <b>pure Indian spices</b> contain their essential
          oils and natural compounds, thus providing maximum flavor and health
          benefits.
        </p>
        <p class="section-content">
          Another reason for selecting <b> pure Indian spices </b>is their
          versatility. They are not just utilized in Indian cuisine but also in
          international cuisine, ranging from Middle Eastern curries to
          Mediterranean food. Their capacity to flavor food with complexity and
          aroma has made them an essential part of professional kitchens.
          Moreover, spices such as saffron and cardamom are frequently used in
          desserts, drinks, and even herbal teas, demonstrating their varied
          uses beyond main courses.
        </p>

        <p class="section-heading">
          <strong>Organic and Pure Indian Spices: A Healthier Option</strong>
        </p>

        <p class="section-content">
          People who look after health and well-being will admire the organic
          and pure Indian spices, as they are naturally free from poisonous
          chemicals and artificial additives. They do not have artificial
          pesticides or chemical fertilizers; the spices used are real and pure.
        </p>
        <p class="section-content">
          <b>Advantages of Organic and Pure Indian Spices:</b>
        </p>
        <p class="section-content">
          <strong>✔ Non-chemical-free:</strong>This means artificial
          preservatives are used.
        </p>
        <p class="section-content">
          <strong>✔ Nutritionally dense:</strong>contains every single important
          vitamin and mineral.
        </p>
        <p class="section-content">
          <strong>✔ Fierceness in flavor:</strong> naturally strengthens taste
          in the prepared food.
        </p>
        <p class="section-content">
          <strong>✔ Health gains—Increases: </strong> The power of digestion
          improves immunity while at the same time guaranteeing good overall
          health.
        </p>
        <p class="section-content">
          At GlobalB2BMart, we provide pure Indian spices of high quality by
          linking businesses with reliable suppliers who stick to high-quality
          standards. With pure spices, consumers and businesses can experience
          the true taste, fragrance, and benefits that these natural products
          have to offer, and every meal becomes a healthier and tastier
          experience
        </p>
        <p class="section-heading">
          <strong>Best Quality Indian Spices—Upgrade Every Meal</strong>
        </p>
        <p class="section-content">
          The <b> best-quality Indian spices</b> are purchased directly from
          those reliable farmers whose farming practices fall under the
          traditional method of cultivation. Throughout the collection,
          processing, and packing process, more care is taken so that these may
          remain fresh with their original smell and taste inside them. The most
          prominent amongst such spices include
        </p>

        <p class="section-content">
          <strong> Turmeric:</strong>
          Contains anti-inflammatory agents in it. Its pretty yellow color gives
          beauty to the dishes and drinks.
        </p>
        <p class="section-content">
          <strong>Cardamom:</strong>A sweet, refreshing smell for sweets and
          liquid products.
        </p>
        <p class="section-content">
          <strong>Cumin (Jeera): </strong>Flavor to curries and digestion aid.
        </p>
        <p class="section-content">
          <strong>Coriander (Dhania):</strong>A basic spice used in Indian
          cooking, gives a citrusy flavor.
        </p>
        <p class="section-content">
          <strong>Black Pepper (Kali Mirch):</strong>Famous as the 'King of
          Spices' for its powerful flavor.
        </p>
        <p class="section-content">
          <strong>Cloves (laung):</strong>Used for their aromatic properties and
          health benefits.
        </p>
        <p class="section-content">
          <strong>Fenugreek (Methi):</strong>Adds a slightly bitter flavor and
          medicinal attributes.
        </p>
        <p class="section-heading">
          <strong> Best Indian Spices: The Soul of Indian Cooking</strong>
        </p>
        <p class="section-content">
          Perhaps the most significant issues regarding the best Indian spices
          are quality and authenticity. Indian spices are well known for the way
          they transform even quite ordinary dishes into tasty food. Here are
          some of the things that define Indian cuisine:
        </p>
        <p class="section-heading">Indian Spices A to Z and How to Use Them:</p>
        <p class="section-content">
          <b>Garam Masala: </b>This is a mixture of various spices, which will
          give depth and warmth to dishes.
        </p>
        <p class="section-content">
          <b>Mustard Seeds (Rai): </b>These impart a brownish color to curries
          and pickles.
        </p>
        <p class="section-content">
          <b>Fennel Seeds (Saunf): </b> It is sweet with a slightly aniseed-like
          taste that can be used in cooking.
        </p>
        <p class="section-content">
          <b>Bay Leaves (Tej Patta):</b> They have a faint aroma added to
          curries and rice recipes.
        </p>
        <p class="section-content">
          <b>Nutmeg (Jaiphal) & Mace (Javitri): </b> They are used in baking,
          spice mix, etc.
        </p>

        <p style={{ marginTop: "10px" }} class="section-heading">
          <strong>
            How GlobalB2BMart Assists Businesses in Identifying the Finest
            Quality Spices
          </strong>
        </p>
        <p class="section-content">
          GlobalB2BMart connects a buyer with genuine, pure Indian spice
          suppliers. We provide you with: A broad supplier network—quality
          spices in excellent quality brought to you at competitive prices.
          Original products: prominent farmers and producers are providing
          farmer's direct spices Healthy transactions-Business deals safely
          encrypted anywhere globally Reach the world. Any of your businesses
          can be globalized very conveniently with GlobalB2BMart.
        </p>
        <p class="section-content">
          ✔Pure authentic Indian spices ✔Direct purchase from the source and
          authentic supplier
        </p>
        <p class="section-content">✔ Bulk competitive price</p>
        <p class="section-content">✔ Effortless trade and logistics support</p>
        <p class="section-content">
          ✔ Favored by businesses all around the world
        </p>

        <p class="section-heading">
          <strong>Last Words</strong>
        </p>
        <p class="section-content">
          It symbolizes that the spices are an icon but a mark of rich heritage
          and flavors reserved inside India, besides the passage on traditions
          carried from inside India. Be it the purest quality in Indian spices,
          organic, pure, or just the best Indian spices, GlobalB2BMart always
          ensures its customers get good-quality products through the authentic
          supplier.
        </p>

        <p class="section-heading">
          <strong>About GlobalB2BMart</strong>
        </p>
        <p class="section-content">
          GlobalB2Bmart is one of the largest B2B sites across the globe, which
          connects companies under various sectors for easy trade and business
          ties. We are helping businesses go global by still allowing them to
          achieve efficiency, quality, and easy deals through our detailed
          network of suppliers and buyers, whom we rely on to assure our
          customers the best service and product for a particular purchase—in
          this case, pure Indian spices or anything at all. Your gateway to
          globalization—GlobalB2BMart.
        </p>
      </div>
    </>
  );
};

export default SpicesFoodProducts;
