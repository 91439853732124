import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    name: "Naresh",
    email: "VighneshENTERPRISES@gmail.com",
    mobileNumber: "8169098604",
    companyName: "Vighnesh ENTERPRISES",
    productOrService: "rain cout",
    imgSrc: "/assets/rain6.jpg", // Provide the correct image path
    mainProducts: "Raincoats, Umbrellas, Waterproof Jackets",
    altText: "Rain Cout - Vighnesh ENTERPRISES",
    years: "1 YRS",
    location: "Delhi India",
    tooltipText: "Delhi India",
    rating: "96%",
    ratingPercent: "96%",
    ratingsCount: "87",
    responseRate: "90",
  },
  {
    _id: "dhananjaya-murthy-champion-filters",
    name: "Mr. Dhananjaya Murthy",
    email: "championfiltersindia@gmail.com",
    password: "championfiltersindia@123",
    mobileNumber: "8571231755",
    companyName: "Champion Filters Manufacturing Company",
    statename: "Andhra Pradesh",
    cityname: "Chittoor",
    productOrService: "Industrial Strainers",
    imgSrc: "/assets/industrial-filter1.jpg",
    mainProducts: "Industrial Strainers",
    altText: "Mr. Dhananjaya Murthy - Manufacturer of Industrial Strainers",
    years: "1 YRS",
    location: "Chittoor, Andhra Pradesh, India",
    tooltipText:
      "Mr. Dhananjaya Murthy - Expert in Industrial Strainers Manufacturing",
    rating: "4.8",
    ratingPercent: "96%",
    ratingsCount: "150",
    responseRate: "95%",
    whatsappConfirmed: true,
  },

  // Add more items if needed
];

const RainCoutComponent = () => {
  return (
    <div className="main-box">
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Related Categories</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Raincoats</Link>
                </li>
                <li>
                  <Link to="#">Umbrellas</Link>
                </li>
                <li>
                  <Link to="#">Waterproof Jackets</Link>
                </li>
                <li>
                  <Link to="#">Rain Boots</Link>
                </li>
                <li>
                  <Link to="#">Rain Hats</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flt-box">
            <p className="flt-title">By State</p>
            <div className="flt-content">
              <div className="flt-search">
                <input
                  type="text"
                  name="state_id"
                  placeholder="Search State"
                  id="state-search-input"
                />
              </div>
              <ul className="flt-list cust-scroll" id="state-lists">
                <li>
                  <Link to="#">All India</Link>
                </li>
                <li>
                  <Link to="#">Maharashtra</Link>
                </li>
                <li>
                  <Link to="#">Gujarat</Link>
                </li>
                <li>
                  <Link to="#">Tamil Nadu</Link>
                </li>
                <li>
                  <Link to="#">Kerala</Link>
                </li>
                <li>
                  <Link to="#">West Bengal</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <main>
        <section className="section">
          <p className="sect-title">Explore by Categories</p>
          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rain1.jpg"
                      width={55}
                      height={55}
                      alt="Raincoats"
                    />
                  </figure>
                  <p className="title">Raincoats</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rain2.jpg"
                      width={55}
                      height={55}
                      alt="Umbrellas"
                    />
                  </figure>
                  <p className="title">Umbrellas</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rain3.jpg"
                      width={55}
                      height={55}
                      alt="Waterproof Jackets"
                    />
                  </figure>
                  <p className="title">Waterproof Jackets</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rain4.jpg"
                      width={55}
                      height={55}
                      alt="Rain Boots"
                    />
                  </figure>
                  <p className="title">Rain Boots</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/rain4.jpg"
                      width={55}
                      height={55}
                      alt="Rain Hats"
                    />
                  </figure>
                  <p className="title">Rain Hats</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <ul className="classfied-wrap">
          {classifiedData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to="#" target="_blank">
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div
                        className="ca-box modal-btn"
                        data-modal="motc"
                        data-src={item.trustCertificateUrl}
                      >
                        <p>
                          <i className="l3icon motc-icon" />
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default RainCoutComponent;
