import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../FreeCataloges.jsx/FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Hings", image: "./assets/hin1.jpg" },
  { id: 3, name: "Nipple", image: "./assets/hin2.jpg" },
  { id: 5, name: "Fastner", image: "./assets/hin3.jpg" },
  { id: 6, name: "Bolts", image: "./assets/hin4.jpg" },
  { id: 1, name: "Screws", image: "./assets/hin5.jpg" },
  { id: 3, name: "Nipple", image: "./assets/hin2.jpg" },
  { id: 5, name: "Fastner", image: "./assets/hin3.jpg" },
  { id: 6, name: "Bolts", image: "./assets/hin4.jpg" },
  { id: 1, name: "Screws", image: "./assets/hin5.jpg" },
];

const RuchikaBhaver = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the Rushikesh Bhavar Enterprises Company</h1>
              <p>
                Rushikesh Bhavar Enterprises focuses on maintaining strict
                quality control measures and timely deliveries, ensuring clients
                receive the best solutions for their specific requirements. With
                a dedicated team of professionals and a strong distribution
                network, Rushikesh Bhavar Enterprises continues to expand its
                reach, serving clients both domestically and internationally.
              </p>
              <h2>We Deals in All kind of Hardware </h2>
              <p>
                Rushikesh Bhavar Enterprises is a trusted name in the
                manufacturing and supply of high-quality industrial hardware,
                including hinges, nipples, fasteners, bolts, and more. With a
                commitment to precision engineering and durability, the company
                has established itself as a reliable partner for businesses
                across various industries, including construction, automotive,
                and heavy machinery.
              </p>
              <p>
                The Importance of Quality Flour Flour quality directly impacts
                the texture, taste, and nutritional value of food. At Jayantipur
                Flour Mill Company, we ensure that our flour is finely milled,
                free from impurities, and retains its natural goodness. Our
                state-of-the-art milling process guarantees consistency,
                freshness, and hygiene, making our products ideal for home cooks
                and large-scale food production.
              </p>
              <p>
                Rushikesh Bhavar Enterprises ensures that all rice varieties
                undergo strict quality control measures, from sourcing to
                packaging. The company prioritizes sustainable farming practices
                and works closely with farmers to maintain the highest quality
                standards. Their extensive distribution network allows them to
                serve both domestic and international markets, making them a
                reliable partner for rice importers and wholesalers worldwide.
              </p>
              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}
              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/screw.jpg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Premium Industrial Fasteners & Hardware</h3>
                    </div>

                    <ul className="attributes">
                      <li>
                        <span>Brand:</span> Rushikesh Bhavar Enterprises
                      </li>
                      <li>
                        <span>Product Range:</span> Hinges, Nipples, Fasteners,
                        Bolts, and Industrial Hardware
                      </li>
                      <li>
                        <span>Material:</span> High-grade stainless steel,
                        carbon steel, and alloy steel for maximum durability.
                      </li>
                      <li>
                        <span>Quality Standard:</span> Manufactured with
                        precision to meet ISO and industrial standards.
                      </li>
                      <li>
                        <span>Strength:</span> Corrosion-resistant, rust-proof,
                        and designed for heavy-duty applications.
                      </li>
                      <li>
                        <span>Usage:</span> Suitable for construction,
                        automotive, heavy machinery, and engineering industries.
                      </li>
                      <li>
                        <span>Design:</span> Engineered for high tensile
                        strength, impact resistance, and long-lasting
                        performance.
                      </li>
                      <li>
                        <span>Packaging:</span> Available in bulk quantities
                        with secure packaging to prevent damage.
                      </li>
                      <li>
                        <span>Finish:</span> Zinc-plated, galvanized, and
                        powder-coated options for enhanced durability.
                      </li>
                      <li>
                        <span>Customization:</span> Custom sizes and
                        specifications available based on client requirements.
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              RB
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  Rushikesh Bhavar Enterprises{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Vadodra, Gujarat
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default RuchikaBhaver;
