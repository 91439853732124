import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Whyglobal from "../Components/Whyglobal";
import BuyerSide from "./BuyerSidebuyer";
import "./OnionBuy.css"; // CSS styling for the Aapalam buyers' section

const AlveBuyer = [
  {
    name: "Hassan Zeb",
    email: "hassanzeb@gmail.com",
    mobileNumber: "923001234567",
    location: "Pakistan",
    date: "04-04-2025",
    lookingFor: "Looking for Aloe Vera Gel for cosmetic purposes.",
    productOrService: "Aloe Vera Gel",
    quantity: 600,
    unit: "Liter",
    _id: "unique_id_aloe_1",
  },
  {
    name: "Dr Laila",
    email: "drlaila@gmail.com",
    mobileNumber: "201234567890",
    location: "Egypt",
    date: "02-04-2025",
    lookingFor: "Looking for transparent thick Aloe Vera Gel.",
    productOrService: "Aloe Vera Gel",
    quantity: 1000,
    unit: "Liter",
    _id: "unique_id_aloe_2",
  },
  {
    name: "Saira Khalid",
    email: "sairakhalid@gmail.com",
    mobileNumber: "923451234567",
    location: "Pakistan",
    date: "02-04-2025",
    lookingFor: "Looking for Aloe Vera Gel for skin and hair care.",
    productOrService: "Aloe Vera Gel",
    quantity: 200,
    unit: "Kilogram",
    _id: "unique_id_aloe_3",
  },
  {
    name: "Vasant",
    email: "vasant.nz@gmail.com",
    mobileNumber: "642345678901",
    location: "New Zealand",
    date: "02-04-2025",
    lookingFor: "Looking for Aloe Vera Gel and Fresh Aloe Vera.",
    productOrService: "Aloe Vera",
    quantity: 100,
    unit: "Container",
    _id: "unique_id_aloe_4",
  },
  {
    name: "Muhammad Aslam Javaid",
    email: "aslamjavaid@gmail.com",
    mobileNumber: "923009876543",
    location: "Pakistan",
    date: "02-04-2025",
    lookingFor: "Looking for Aloe Vera Gel in bulk.",
    productOrService: "Aloe Vera Gel",
    quantity: 5000,
    unit: "Kilogram",
    _id: "unique_id_aloe_5",
  },
  {
    name: "Neha Agrawal",
    email: "neha.agrawal@gmail.com",
    mobileNumber: "977123456789",
    location: "Nepal",
    date: "01-04-2025",
    lookingFor: "Looking for 100 units of Fresh Aloe Vera Baby Plants.",
    productOrService: "Aloe Vera Baby Plant",
    quantity: 1000,
    unit: "Units",
    _id: "unique_id_aloe_6",
  },
  {
    name: "Ryan Pincura",
    email: "ryan.pincura@gmail.com",
    mobileNumber: "15123456789",
    location: "Canada",
    date: "29-03-2025",
    lookingFor: "Looking for bulk Aloe Vera in food-grade containers.",
    productOrService: "Aloe Vera",
    quantity: 10,
    unit: "Metric Ton",
    _id: "unique_id_aloe_7",
  },
  {
    name: "Rocky Hizon",
    email: "rocky.hizon@gmail.com",
    mobileNumber: "669876543210",
    location: "Thailand",
    date: "09-03-2025",
    lookingFor: "Looking for Aloe Vera Dice, Crushed, and Juice in bulk.",
    productOrService: "Aloe Vera Dice, Crushed, and Juice",
    quantity: 10,
    unit: "Container",
    _id: "unique_id_aloe_8",
  },
  {
    name: "Dan",
    email: "dan.egypt@gmail.com",
    mobileNumber: "201987654321",
    location: "Egypt",
    date: "05-03-2025",
    lookingFor: "Looking for Aloe Vera Powder in bulk.",
    productOrService: "Aloe Vera Powder",
    quantity: 1000,
    unit: "Kilogram",
    _id: "unique_id_aloe_9",
  },
  {
    name: "Chetan Jangid",
    email: "chetan.jangid@gmail.com",
    mobileNumber: "919876543210",
    location: "India",
    date: "12-03-2025",
    lookingFor: "Looking for Fresh Aloe Vera Leaves in bulk.",
    productOrService: "Fresh Aloe Vera Leaves",
    quantity: 100,
    unit: "Ton",
    _id: "unique_id_aloe_10",
  },
];

const AapalamBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`aapalamViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return;

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return;

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    localStorage.setItem(
      `aapalamViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = AlveBuyer[index];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/"}>Home</Link>
          </li>
          <li className="breadcrumb-item active">Aapalam Buyers</li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Gujarat</Link>
            </li>
          </ul>
          <ul className="flt-list cust-scroll">
            <h3>All Varieties</h3>
            <ul className="flt-list cust-scroll">
              <li>
                <Link to="#">Aloe Barbadensis Miller</Link>
              </li>
              <li>
                <Link to="#">Aloe Arborescens</Link>
              </li>
              <li>
                <Link to="#">Aloe Ferox</Link>
              </li>
              <li>
                <Link to="#">Aloe Perryi</Link>
              </li>
              <li>
                <Link to="#">Aloe Maculata</Link>
              </li>
            </ul>
          </ul>
        </div>
        <div className="buyers">
          {AlveBuyer.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "blue" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location </strong> {buyer.location}
              </p>
              <p>
                <strong>Date:</strong> {buyer.date}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <button
                className="detail-btn"
                onClick={() => toggleNumber(index)}
                disabled={!userIsPremium}
              >
                <div className="btns-imp">
                  <Link to={"/login"}>
                    <button className="detailing-btn">View More</button>
                  </Link>
                  <Link to={"/register-Company"}>
                    <button className="regis-btn">Register Now</button>
                  </Link>
                </div>
              </button>
            </div>
          ))}
        </div>
        <BuyerSide />
      </div>
      <Whyglobal />
    </>
  );
};

export default AapalamBuy;
