import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    _id: "j6789012345k678901",
    name: "Surabh",
    email: "surabh123@gmail.com",
    password: "surabh1234",
    mobileNumber: "8178949565",
    companyName: "Shri Balaji Enterprisses",
    productOrService: "Garment",
    imgSrc: "/assets/indiandress1.jpeg",
    mainProducts: "Men's Wear, Women's Wear, Kids' Garments",
    altText: "Garments - Shri Balaji Enterprisses",
    years: "1 YRS",
    location: "Jaipur, Rajasthan, India",
    tooltipText: "Sector 8, Industrial Park, Jaipur, Rajasthan, India",
    rating: "4.8",
    ratingPercent: "95%",
    ratingsCount: "89",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "i3456789012abc7890",
    name: "Md Irfan Ansari",
    email: "ansari123@gmail.com",
    password: "ansari1234",
    mobileNumber: "7905636511",
    companyName: "A M Sons",
    productOrService: "Kurti",
    imgSrc: "/assets/kurti1.jpeg", // Placeholder for image path
    mainProducts: "Designer Kurtis, Cotton Kurtis, Embroidered Kurtis",
    altText: "A M Sons - Kurti Collection",
    years: "1 YRS", // Placeholder for years of experience
    location: "Jaipur, Rajasthan, India", // Placeholder for location
    tooltipText: "A M Sons, Jaipur",
    rating: "4.6", // Placeholder for rating
    ratingPercent: "92%", // Placeholder for rating percentage
    ratingsCount: "78", // Placeholder for ratings count
    responseRate: "87%", // Placeholder for response rate
    whatsappConfirmed: true,
  },
  {
    _id: "b1234567890xyz5681",
    name: "My Style Look",
    email: "rb7610992@gmail.com",
    password: "8058632003",
    mobileNumber: "8058632003",
    companyName: "My Style Look",
    productOrService: "Kurtis",
    imgSrc: "/assets/kurti2.jpeg",
    mainProducts: "Designer Kurtis, Ethnic Wear, Women's Apparel",
    altText: "My Style Look - Kurtis",
    years: "1 YRS",
    location: "Jaipur, Rajasthan, India",
    tooltipText: "My Style Look, Jaipur",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "85",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "b1c2d3e4f5g6h7i8j9k0l1m2", // Random unique ID
    name: "Pulkit Khandelwal",
    email: "shrinathdistributor9@gmail.com",
    mobileNumber: "7849811870",
    companyName: "Shri Nath Distributors",
    productOrService: "Garments",
    imgSrc: "/assets/garment4.jpeg", // Example image path
    mainProducts:
      "Men's Wear, Women's Wear, Kids' Clothing, Fashion Accessories",
    altText: "Garments - Shri Nath Distributors",
    years: "1 YRS",
    location: "Delhi, India",
    tooltipText: "23 Garment Lane, Delhi, India",
    rating: "4.3",
    ratingPercent: "88%",
    ratingsCount: "60",
    responseRate: "91%",
    whatsappConfirmed: true,
  },
  {
    name: "Silambarasan",
    email: "8610812075@gmail.com",
    password: "8610812075",
    mobileNumber: "8610812075",
    companyName: "Ik Enterprise",
    productOrService: "Clay Handicraft, Honey, Nuts",
    imgSrc: "/assets/garment7.jpg",
    mainProducts: "Clay Handicrafts, Honey, Nuts",
    altText: "Ik Enterprise - Clay Handicraft, Honey, Nuts",
    years: "1 YRS",
    location: "Tiruvallur, Tamil Nadu, India",
    tooltipText: "Ik Enterprise, Tamil Nadu",
    rating: "4.2",
    ratingPercent: "84%",
    ratingsCount: "60",
    responseRate: "90%",
    whatsappConfirmed: false,
  },
  {
    _id: "vmg-fashion-delhi",
    name: "Vijay",
    email: "abc@gmail.com",
    password: "vijay@123",
    mobileNumber: "9310704440",
    companyName: "VMG Fashion",
    productOrService: "Ladies Kurti",
    imgSrc: "/assets/vmg.jpg", // Placeholder image path
    mainProducts: "Ladies Kurti",
    altText: "VMG Fashion - Ladies Kurti",
    years: "1 YRS", // Random value
    location: "Delhi, Delhi, India",
    tooltipText: "VMG Fashion, Ladies Kurti, Delhi",
    rating: "4.7", // Random value
    ratingPercent: "93%", // Random value
    ratingsCount: "35", // Random value
    responseRate: "85%", // Random value
    whatsappConfirmed: true,
  },
  {
    _id: "farhaz_sunshinelaser",
    name: "Farhaz",
    email: "SUNSHINELASERS@GMAIL.COM",
    password: "8375000867",
    mobileNumber: "8433875466",
    companyName: "Sunshine Laser",
    statename: "Maharashtra",
    cityname: "Mumbai",
    productOrService: "Kurti",
    imgSrc: "/assets/kurti12.jpg",
    mainProducts: "Designer Kurtis, Ethnic Wear, Custom Embroidery",
    altText: "Sunshine Laser - Expert in Designer Kurtis & Ethnic Wear",
    years: "1 YRS",
    location: "Mumbai, Maharashtra",
    tooltipText: "Farhaz - Specialized in Laser Cutting & Ethnic Wear Design",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "180",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
];

const LadiesReadymadeGarment = () => {
  return (
    <>
      <div className="main-box">
        <aside>
          <div className="flt-box-wrap">
            <div className="flt-box mb-0 flt-head">Filters By</div>
            <div className="flt-box bdrt-0">
              <p className="flt-title">Related Categories</p>
              <div className="flt-content">
                <ul className="flt-list cust-scroll">
                  <li>
                    <Link to="#">Men's Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Women's Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Kids' Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Ethnic Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Formal Wear</Link>
                  </li>
                  <li>
                    <Link to="#">Casual Wear</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flt-box">
              <p className="flt-title">By State</p>
              <div className="flt-content">
                <div className="flt-search">
                  <input
                    type="text"
                    name="state_id"
                    placeholder="Search State"
                    id="state-search-input"
                  />
                </div>
                <ul className="flt-list cust-scroll" id="state-lists">
                  <li>
                    <Link to="#">All India</Link>
                  </li>
                  <li>
                    <Link to="#">Maharashtra</Link>
                  </li>
                  <li>
                    <Link to="#">Gujarat</Link>
                  </li>
                  <li>
                    <Link to="#">Delhi</Link>
                  </li>
                  <li>
                    <Link to="#">Tamil Nadu</Link>
                  </li>
                  <li>
                    <Link to="#">Uttar Pradesh</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <main>
          <section className="section">
            <p className="sect-title">Explore by Categories</p>
            <div className="horprd expcatg" id="expcatg">
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/gr1.jpeg"
                        width={55}
                        height={55}
                        alt="Men's Wear"
                      />
                    </figure>
                    <p className="title">Men's Wear</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/gr2.jpeg"
                        width={55}
                        height={55}
                        alt="Women's Wear"
                      />
                    </figure>
                    <p className="title">Women's Wear</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/gr3.jpeg"
                        width={55}
                        height={55}
                        alt="Kids' Wear"
                      />
                    </figure>
                    <p className="title">Kids' Wear</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/gr5.jpeg"
                        width={55}
                        height={55}
                        alt="Ethnic Wear"
                      />
                    </figure>
                    <p className="title">Ethnic Wear</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/formal.jpg"
                        width={55}
                        height={55}
                        alt="Formal Wear"
                      />
                    </figure>
                    <p className="title">Formal Wear</p>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <ul className="classfied-wrap">
            {classifiedData.map((item, index) => (
              <li key={index}>
                <div className="classified">
                  <div className="prd-info">
                    <div className="prd-box">
                      <img
                        src={item.imgSrc}
                        alt={item.altText}
                        width={250}
                        height={250}
                      />
                    </div>
                  </div>
                  <div className="cinfo">
                    <div className="cbox">
                      <figure>
                        <span className="cmp-year">{item.years}</span>
                      </figure>
                      <div className="cboxr">
                        <Link to="#" target="_blank">
                          <h4 className="title">{item.companyName}</h4>
                        </Link>
                        <p className="cloc tooltip ellipsis">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi-location"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                          </svg>
                          {item.location}
                          <span className="tooltiptext">
                            {item.tooltipText}
                          </span>
                        </p>
                        <div className="rating-wrap">
                          <span className="rtbox">{item.rating}</span>
                          <span
                            className="crate"
                            style={{ "--_score": item.ratingPercent }}
                          />
                          <span className="rate-text">
                            {item.ratingsCount} Ratings
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="caddit">
                      <div className="item">
                        <div className="ca-box">
                          <p>
                            <i className="l3icon resp-icon" />
                          </p>
                          <p>
                            <span>Response Rate</span>{" "}
                            <b>{item.responseRate}</b>
                          </p>
                        </div>
                        <p>
                          <span>Main Products:</span> <b>{item.mainProducts}</b>
                        </p>
                      </div>
                    </div>
                    <div className="caction">
                      <Link to={"/register-buyer"}>
                        <p>Contact Supplier</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </main>
      </div>
      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Ladies Readymade Garments | GlobalB2BMart
        </h2>

        <p class="section-content">
          Welcome to GlobalB2BMart, the reliable online site for purchasing
          Ladies Readymade Garments in India. We make it easier to connect
          companies with trusted manufacturers and suppliers so that retailers,
          distributors, and fashion designers can have access to products that
          suit the needs of their markets. We emphasize quality, variety, and
          reasonable pricing so that you can create a complete stock of ladies
          readymade garments, personalized apparel, and more.
        </p>
        <p class="section-content">
          At GlobalB2BMart, we realize that a consistent and reliable supply
          chain is vital in the fashion business. We have suppliers who provide
          a vast selection of ready-made apparel that appeals to various
          consumer preferences. If you are looking for casual wear or for
          occasion wear, our listings contain an assortment of styles that
          appeal to contemporary tastes.
        </p>
        <p class="section-content">
          In addition to ladies ready-made garments, our platform provides
          access to custom clothing solutions. Manufacturers listed on
          GlobalB2BMart pay close attention to detail, ensuring that each piece
          meets high standards of quality. This allows you to offer your
          customers unique products that reflect their personal style and the
          latest trends.
        </p>
        <p class="section-content">
          Our promise is to assist your company's expansion with dependable,
          current product details and access to vendors that have very high
          quality standards. We do our best to make it convenient for you to
          maintain your stock and ensure customer satisfaction with the newest
          ladies' fashion.
        </p>
        <p class="section-content">
          For more questions or to begin sourcing today, we invite you to
          contact us. Your success in the fashion industry begins with the right
          associates, and GlobalB2BMart is always here to help you along the
          way.
        </p>
        <p class="section-heading">
          <strong>Best Quality Ladies Readymade Garments in India</strong>
        </p>
        <p class="section-content">
          <b>Best quality ladies readymade garments</b> in India are available
          through our extensive network of suppliers. Our partners follow strict
          quality standards, ensuring that every garment is carefully produced.
          Each item is made with accuracy, so it not only looks appealing but is
          also durable. Whether you need large volume orders for retail chains
          or smaller quantities for boutique stores, our suppliers deliver
          clothing that meets high standards. Their work covers a wide variety
          of styles, from ethnic to western, making them suitable for diverse
          customer needs.
        </p>

        <p class="section-heading">
          <strong>Modern Ladies Readymade garments</strong>
        </p>
        <p class="section-content">
          <b> Modern ladies readymade garments</b>forms the core of our
          offerings. Our platform brings together suppliers who provide a wide
          range of ladies readymade garments that is both stylish and practical.
          The collection includes dresses, tops, sarees, and other garments that
          reflect current fashion trends and everyday wear. Our verified
          suppliers work to ensure that each piece meets the tastes of today’s
          consumers while maintaining consistent quality. This range helps
          businesses keep their stock updated with designs that appeal to modern
          buyers.
        </p>

        <p class="section-heading">
          <strong>Affordable Ladies Readymade garments </strong>
        </p>
        <p class="section-content">
          <b>Affordable Ladies Readymade garments</b> is another important
          segment on GlobalB2BMart. Our suppliers provide clothing options that
          balance cost and style. Businesses can source garments that are
          budget-friendly yet follow the latest trends, ensuring they remain
          competitive in the market. This selection is ideal for companies that
          need to update their inventory regularly while keeping expenses in
          check. With a range of choices available at fair prices, you can offer
          stylish apparel without compromising on quality.
        </p>

        <p class="section-heading">
          <strong>Best ladies Ready-Made garments in India</strong>
        </p>
        <p class="section-content">
          We also feature suppliers who offer the best{" "}
          <b>ladies readymade garmnets in India.</b> This category includes a
          diverse selection of garments ranging from traditional ethnic wear to
          modern western styles. Our suppliers use quality materials and sound
          production methods to produce clothing that stands out in design and
          durability. This variety ensures that businesses can offer complete
          wardrobe solutions that appeal to a wide audience.
        </p>

        <p class="section-heading">
          <strong>Garments Manufacturers and Custom Solutions</strong>
        </p>
        <p class="section-content">
          In addition to <b>ladies ready-made garments,</b> GlobalB2BMart
          connects you with reliable garments manufacturers who offer
          custom-made clothing solutions. These manufacturers work closely with
          businesses to produce garments that meet specific requirements. They
          use modern production methods and maintain quality control to ensure
          every order is accurate and of high quality. This service is
          especially useful for retailers and designers who require custom
          embroidery, specific fabric choices, or customize fits for their
          brand.
        </p>

        <p class="section-heading">
          <strong>Apparel Suppliers and Cloth Manufacturers</strong>
        </p>
        <p class="section-content">
          Alongside the above segments, GlobalB2BMart connects you with reliable
          apparel suppliers and cloth manufacturers. These suppliers offer a
          comprehensive range of materials and finished products that keep you
          updated with the latest fashion trends. Their collections include
          everything from basic fabrics to intricate designs that help build a
          complete clothing line. With this network, businesses can secure a
          steady supply of quality products at competitive prices.
        </p>
        <p class="section-heading">
          <strong>Why Choose GlobalB2BMart?</strong>
        </p>
        <p class="section-content">
          At GlobalB2BMart, we are dedicated to provide a clear and reliable
          platform for sourcing quality{" "}
          <b>ladies readymade garments in India.</b> Our network of verified
          suppliers and manufacturers is committed to deliver high-quality
          products that meet market demands. We continuously update our catalog
          to reflect current trends, ensuring that you have access to the best
          options available. Our goal is to help your business grow by providing
          the right connections and products needed for success.
        </p>
      </div>
    </>
  );
};

export default LadiesReadymadeGarment;
