import React from "react";
import { Link } from "react-router-dom";
import "./blog.css";
<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap');
</style>;

const SeedsBlogs = () => {
  return (
    <>
      <div style={{ fontFamily: "Oleo Script" }} className="blog-container">
        <header className="blog-header">
          {/* <h2 className="blog-intro" style={{ margin: "30px 0" }}>
            The Apple: A Nutritional Powerhouse with a Rich History
          </h2> */}
        </header>
        <main className="blog-main">
          <article className="blog-article">
            <Link to={"/"}>
              <img
                src="./assets/seeds-blog1.jpg"
                alt="globalb2bmart"
                className="blog-image"
              />
            </Link>
            <div className="blog-body">
              <div className="blog-intro">
                <h1>Introduction </h1>
              </div>
              <p>
                Organic seeds form part of a clean way of living and
                environmentally friendly husbandry. From being a gardener or a
                planter to health-focused people, organic seeds profit with
                numerous cons. Compared to seeds that have been produced by
                using chemical-ground fungicides, dressings, and diseases,
                organic seeds are produced in their natural state to offer a
                healthier and cleaner end product.
              </p>
              <p>
                In this expansive companion, we will discover several organic
                seed kinds, their advantages, and how they can be added to your
                diet. We'll also address their nutritive value and how they help
                you overall.
              </p>
              <p>
                Organic seeds are important for maintaining biodiversity and
                ecological balance. By using organic seeds, home gardeners and
                growers help insure a healthy terrain by mollifying the chemical
                runoff into water bodies, soil declination, and water impurity.
                Organic civilization also increases soil fertility, resulting in
                healthier and more bountiful crops in the long run.
              </p>
              <p>
                An alternate important benefit of organic seeds is their
                exceptional rigidity to natural growing conditions. While
                genetically modified seeds may need special chemical treatments,
                organic seeds are by nature more flexible. They can tolerate
                variable climates, insects, and soil conditions and therefore
                represent a further sustainable option for long-term agrarian
                substances.
              </p>
              <p>
                Nutritionally, organic seeds contain all the vital nutrients,
                such as proteins, good fats, fiber, vitamins, and minerals.
                Organic seeds are generally more advanced in antioxidants than
                those grown conventionally, which is helpful in fighting
                oxidative stress and precluding habitual conditions. Adding
                organic seeds to one's diurnal refections can give energy for a
                longer period, promote digestion, and maintain overall health.
              </p>
              <p>
                For those who are planning to take up a factory-ground diet,
                organic seeds are a great source of protein. They contain
                important amino acids that are demanded for muscle growth,
                vulnerable responses, and repairing cells. Most factory-grounded
                consumers use seeds like chia, flax, and sunflower as part of
                their diurnal protein input without resorting to beast products.
              </p>
              <p>
                Beyond health and sustainability, organic seeds also contribute
                to ethical food products. Numerous organic seed suppliers
                prioritize fair trade practices, ensuring that growers admit
                fair stipends and work in safe conditions. By choosing organic
                seeds, consumers can support responsible agrarian practices
                while enjoying nutrient-rich and chemical-free food options.
              </p>
              <p>
                In the coming sections, we will explore colorful kinds of
                organic seeds, their benefits to health, and simple ways to
                include them in your life.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Popular Organic Seed kinds and Their Benefits
              </h3>
              <h3 style={{ margin: "40px 0px" }}>
                1. Sunflower Seeds & Sunflower Kernels
              </h3>
              <Link to={"/"}>
                <img
                  src="./assets/seed5.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Sunflower seeds are a nutritive hustler, with vitamin E,
                magnesium, and healthy fats being some of the crucial nutrients
                they contain. Sunflower kernels, the inner core of the seed, are
                nutritional and can be used in a variety of cookeries. They're
                protean and can be eaten in different forms, like raw, roasted,
                or used in fashion.
              </p>
              <h3 style={{ margin: "40px 0px" }}>Benefits</h3>
              <p>Rich in heart-friendly antioxidants</p>
              <p>
                Sunflower seeds are rich in vitamin E, a vital antioxidant that
                aids in lowering oxidative stress in the body. The antioxidant
                action helps bring about better cardiovascular health by
                stopping cholesterol oxidation, lowering inflammation, and
                reducing heart complaint threat.
              </p>
              <p>
                Rich in factory protein With around 21 grams of protein per 100
                grams, sunflower seeds are a great factory protein source and a
                perfect supplement to submissive and vegan diets. The protein is
                useful for repairing muscles, boosts metabolism, and gives
                long-lasting energy during the day.
              </p>
              <p>
                Promotes skin health due to its high vitamin E content The high
                concentration of vitamin E in sunflower seeds nourishes the skin
                by guarding it from damage caused by ultraviolet (UV) shafts and
                environmental adulterants. Regular consumption of sunflower
                seeds can help maintain skin pliantness, reduce signs of aging,
                and promote a healthy, radiant complexion.
              </p>
              <h3 style={{ margin: "40px 0px" }}>How to Use</h3>
              <p>
                Use in salads, smoothies, and ignited foods Sunflower seeds add
                texture and nutrition to salads, giving them a delicious crunch.
                When added to smoothies, they add healthy fats and protein,
                making the libation more stuffing. In baked foods, like muffins
                or chuck , sunflower seeds give a nutty taste and increase
                overall nutrition.
              </p>
              <p>
                Serve as a roasted snack Teased sunflower seeds can be a nice
                and accessible snack. Riding them smoothly with a gusto of ocean
                swab or sauces brings out the stylish flavor. They're a great
                cover for packaged snacks and offer sustained energy.
              </p>
              <p>
                Use in manual granola or energy bars Sunflower seeds can be an
                ideal addition to manual granola and energy bars. They
                contribute crunch, natural canvases , and necessary nutrients
                and make them an ideal component for healthy, quick mouthfuls.
                They combine well with oats, honey, and dry fruits to give a
                healthy and delicious snack.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                2. Flax Seeds & Linen Seeds
              </h3>
              <Link to={"/"}>
                <img
                  src="./assets/seed4.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Linen seeds, which are also called flax seeds, have long been
                prized for their health attributes. They're rich in fiber,
                omega-3 adipose acids, and lignans, and the ultimate of these
                contains antioxidant attributes.
              </p>
              <h3 style={{ margin: "40px 0px" }}>Benefits</h3>
              <p>
                Supports digestive health because of high fiber content Flax
                seeds are a rich source of both answerable and undoable fiber.
                Answerable fiber traps water and creates a gel-like texture,
                decelerating digestion and stabilizing blood sugar situations.
                undoable fiber bulkifies coprolite, encouraging regular bowel
                movements and avoiding constipation. Regular input of flax seeds
                can enhance gut health and maintain a healthy microbiome.
              </p>
              <p>
                Lowers cholesterol situations and heart health. The inordinate
                quantum of omega-3 adipose acids, nascent linolenic acid (ALA),
                present in flax seeds lowers bad cholesterol (LDL) situations
                and raises good cholesterol (HDL) situations. Exploration
                indicates that regular input of flax seeds can help lower the
                prevalence of heart complaints by lowering inflammation and
                enhancing the overall cardiovascular well-being. Lignans set up
                in flax seeds also help in keeping blood pressure situations
                within healthy limits.
              </p>
              <p>
                Helps in weight control by causing malnutrition Flax seeds
                contain salutary fiber and essential fats, which both play a
                part in making one feel full after consumption. This helps cut
                down overall calorie consumption and insure healthy weight
                control. The synergistic effect of fiber and omega- 3 adipose
                acids balances out the metabolism, and therefore flax seeds are
                a superb choice to be added to a healthy diet when one wants to
                lose or maintain weight.
              </p>
              <h3 style={{ margin: "40px 0px" }}>How to Use</h3>
              <p>
                Add to oatmeal, yogurt, or smoothies Ground flax seeds are
                simple to add to your breakfast coliseum or mix into smoothies
                for an added nutritive kick. They've a mild, nutty taste that
                dyads well with numerous foods without dominating the flavor.
              </p>
              <p>
                Use as an egg relief in baking (flax egg) For those who are on a
                factory-ground diet or simply need an egg volition, flax seeds
                can be used as a binding agent for baked foods. To produce a
                "flax egg," combine one teaspoon of ground flax seeds with three
                soupspoons of water and allow it to sit for many twinkles until
                it's gel-like. This can be applied to muffins, flapjacks, and
                more.
              </p>
              <p>
                Sprinkle over mists and salads Whole or ground flax seeds are
                added to salads and mists to give texture and nutrition a boost.
                Seeds may give a bit of crunch to salads, but ground flax seeds
                can be added to mists for a nutty flavor and an infusion of
                healthy omega- 3s.
              </p>
              <h3 style={{ margin: "40px 0px" }}>3. Chia Seeds</h3>
              <Link to={"/"}>
                <img
                  src="./assets/chia-seed1.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Chia seeds are small but nutrient-rich superfoods. They're most
                extensively known for having high fiber and omega-3 content.
                They retain the capacity to soak water and swell and come
                glutinous in nature, making them veritably hydrating and helpful
                with digestion.
              </p>
              <h3 style={{ margin: "40px 0px" }}>Advantages</h3>
              <p>
                Sustains weight operation (chia seeds for weight loss) Owing to
                their uproariousness in fiber, chia seeds retain water and form
                a large mass in the stomach, furnishing the sensation of being
                full, hence minimizing calorie consumption. They're therefore a
                good supplement for diets aimed at losing weight, as they're
                suitable to suppress appetite stings and insure one is
                satisfied.
              </p>
              <p>
                Excellent source of protein in chia seeds that's perfect for
                insects Chia seeds give a good volume of factory protein, which
                is an excellent protein source for both insectivores and
                insectivores. The protein sustains muscle development, recovery,
                and total energy force.
              </p>
              <p>
                Aids digestion and gut health High fiber in chia seeds supports
                healthy digestion through the addition of bulk to coprolite and
                aids in bowel chronicity. As a prebiotic, they also feed
                salutary bacteria in the gut, leading to better gut health.
              </p>
              <h3 style={{ margin: "40px 0px" }}>How to Use</h3>
              <p>
                Prepare chia pudding by soaking them in milk or yogurt. Chia
                pudding is easy and healthy to consume chia seeds. Soak them in
                milk, yogurt, or a dairy-free milk overnight, and they will soak
                up the liquid and come gel-like. Top them with honey, fruit, or
                nuts for fresh flavor.
              </p>
              <p>
                Use in smoothies for a richer texture Grinding chia seeds with
                smoothies does n't only ameliorate their nutritive viscosity but
                also provides a thicker thickness to the smoothie, making the
                smoothie more full and satisfying.
              </p>
              <p>
                Use to eclipse breakfast foods or salads Using chia seeds to top
                cereal, oatmeal, or salads offers a veritably simple system for
                adding further fiber, protein, and healthy fat to your dish.
              </p>
              <h3 style={{ margin: "40px 0px" }}>4. Mustard Seeds</h3>
              <Link to={"/"}>
                <img
                  src="./assets/seed6.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Mustard seeds are generally employed in cooking for their strong
                odor and unique flavor. They're offered in several types,
                including black, brown, and unheroic mustard seeds, each
                furnishing a little different flavor and degree of spiciness.
              </p>
              <h3 style={{ margin: "40px 0px" }}>Benefits</h3>
              <p>
                Aids metabolism and digestion Mustard seeds have ingredients
                which encourage the stashing of digestive enzymes, icing
                effective digestion. They also help in bridling gas conformation
                and bloating, making them a great result for digestive
                disturbances.
              </p>
              <p>
                Hasanti-inflammatory ingredients With selenium and magnesium
                content, mustard seeds check inflammation and ease symptoms of
                conditions like arthritis and muscle pangs.
              </p>
              <p>
                assistances in controlling blood sugar situations Mustard seeds
                have been known to increase the perceptivity of insulin and
                reduce blood sugar situations, therefore being great for people
                who are living with diabetes.
              </p>
              <h3 style={{ margin: "40px 0px" }}>How to use </h3>
              <p>
                Use in pickling and gravies Mustard seeds are extensively
                employed in pickling fashions to add flavor and act as a
                preservative. They also serve well as a component in gravies for
                meat and vegetables, offering a pungent and slightly hot flavor.
              </p>
              <p>
                Use in curries and stir-feasts Ground or whole mustard seeds
                give depth and uproariousness to stir-feasts, curries, and
                lentil medications. They may be tempered in hot oil painting
                prior to adding the other constituents to release their unique
                aroma and flavor.
              </p>
              <p>
                Use in manual mustard sauce Mustard seeds can be mulled into a
                fine paste and blended with ginger, honey, and spices to make
                delicious manual mustard gravies that round sandwiches,
                dressings, and roasted foods.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Nutritive Value of Organic Seeds
              </h3>
              <Link to={"/"}>
                <img
                  src="./assets/seed8.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Organic seeds are full of essential vitamins, minerals, and
                antioxidants. Then's a nutritive comparison of popular organic
                seeds per 100g
              </p>

              <table
                style={{
                  width: "90%",
                  margin: "20px auto",
                  borderCollapse: "collapse",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f4f4f4" }}>
                    {[
                      "Seed Type",
                      "Protein (g)",
                      "Fiber (g)",
                      "Omega-3 (mg)",
                      "Vitamin E (mg)",
                      "Calcium (mg)",
                    ].map((header) => (
                      <th
                        key={header}
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      type: "Sunflower Seeds",
                      protein: 21,
                      fiber: 9,
                      omega: 0,
                      vitaminE: 35,
                      calcium: 78,
                    },
                    {
                      type: "Flax Seeds",
                      protein: 18,
                      fiber: 27,
                      omega: 22800,
                      vitaminE: 0.3,
                      calcium: 255,
                    },
                    {
                      type: "Chia Seeds",
                      protein: 17,
                      fiber: 34,
                      omega: 17500,
                      vitaminE: 0.2,
                      calcium: 631,
                    },
                    {
                      type: "Mustard Seeds",
                      protein: 26,
                      fiber: 12,
                      omega: 0,
                      vitaminE: 5,
                      calcium: 266,
                    },
                  ].map((seed, index) => (
                    <tr
                      key={seed.type}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {seed.type}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {seed.protein}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {seed.fiber}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {seed.omega.toLocaleString()}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {seed.vitaminE}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {seed.calcium}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h3 style={{ margin: "40px 0px" }}>
                Nutritional Components Detailed Analysis
              </h3>
              <h3 style={{ margin: "40px 0px" }} s>
                1. Protein Content
              </h3>
              <Link to={"/"}>
                <img
                  src="./assets/seed-data.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Muscle structure, form of apkins, and normal body functions all
                depend on proteins. Mustard seeds contain the maximum quantum of
                protein among the seeds mentioned, making them a precious
                element in a high-protein diet. Sunflower seeds are also high in
                protein content, making them an excellent source for
                factory-ground diets.
              </p>

              <h3 style={{ margin: "40px 0px" }}>2. Fiber Content</h3>
              <Link to={"/"}>
                <img
                  src="./assets/seed9.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Fiber is essential for gut and digestion health. Chia seeds top
                the list in fiber content, followed by flax seeds. A diet of
                fiber-rich seeds helps maintain regular bowel movements, lowers
                bloating, and promotes gut microbiome health.
              </p>
              <h3 style={{ margin: "40px 0px" }}>3. Omega- 3 Adipose Acids</h3>
              <Link to={"/"}>
                <img
                  src="./assets/omega-seeds1.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Omega-3s are health fats that aid in maintaining heart health
                and lowering inflammation. They're set up in cornucopia in flax
                seeds, also in chia seeds. Adding these seeds to recipes daily
                may ameliorate cardiovascular function and brain health.
              </p>

              <h3 style={{ margin: "40px 0px" }}>4. Vitamin E Content</h3>
              <Link to={"/"}>
                <img
                  src="./assets/vitamin-e1.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Vitamin E is a potent antioxidant that preserves healthy skin,
                eyes, and vulnerable systems. Sunflower seeds are the richest
                source of vitamin E and therefore an ideal component in a
                skin-friendly diet.
              </p>
              <h3 style={{ margin: "40px 0px" }}>5. Calcium Content</h3>
              <Link to={"/"}>
                <img
                  src="./assets/vitaminee3.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Calcium is demanded for healthy bones and teeth. Chia seeds have
                the most calcium content, and they're a good choice for
                individuals who do not eat dairy foods. Mustard and flax seeds
                also add a significant quantum of calcium to the body, which
                aids bone health.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                How to Store Organic Seeds for Maximum Freshness
              </h3>

              <Link to={"/"}>
                <img
                  src="./assets/b2b-services1.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                To keep organic seeds fresh and retain their nutritive content,
                heed these storehouse advice
              </p>
              <h4 style={{ margin: "40px 0px" }}>
                Store in Airtight Containers away from Heat and humidity
              </h4>

              <p>
                Seed exposure to heat and humidity can beget seed corruption,
                earth, and rancidity. To prolong their shelf life, keep organic
                seeds in watertight glass jars, vacuum-sealed bags, or BPA-free
                plastic holders. Keep them down from ranges and direct sun to
                avoid gratuitous heat exposure.
              </p>
              <h4 style={{ margin: "40px 0px" }}>
                Refrigerate Flax Seeds and Chia Seeds to Avoid Rancidity
              </h4>
              <p>
                Flax seeds and chia seeds contain high quantities of omega-3
                adipose acids, which are prone to oxidation and corruption when
                exposed to air and light. To retain their newness and nutritive
                quality, keep them cooled in a sealed vessel. For long-term
                storage, they can also be placed in the freezer, where they can
                last up to a time.
              </p>
              <h4 style={{ margin: "40px 0px" }}>
                Keep Sunflower Seeds and Mustard Seeds in a Cool, Dark Place
              </h4>
              <p>
                Sunflower seeds and mustard seeds retain a fairly stable shelf
                life, yet they must be stored in a dry, cool place to avoid
                getting banal. Store them in a cupboard or closet where moisture
                and temperature aren't present. However, resolve them into
                portions and store the remainder in vacuum-sealed packets to
                maintain their newness for a longer period of time if buying in
                bulk.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Frequently Asked Questions (FAQs)
              </h3>
              <Link to={"/"}>
                <img
                  src="./assets/faq7.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <h3 style={{ margin: "40px 0px" }}>
                1. How do sunflower seeds and sunflower kernels differ?
              </h3>
              <p>
                Sunflower seeds are the whole seed, shell included, while
                sunflower kernels are the kernel or comestible insides of the
                seed without the shell. Sunflower kernels are easier to consume
                and cook directly, whereas whole sunflower seeds tend to be
                roasted and eaten as a snack.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                2. Are organic seeds superior to conventional seeds?
              </h3>
              <p>
                Yes, organic seeds are grown without dangerous fungicides and
                chemicals, making them a healthier option for consumption and
                the environment. They also tend to have an advanced nutrient
                profile and support biodiversity by promoting natural husbandry
                styles.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                3. Can I consume chia seeds every day?
              </h3>
              <p>
                Yes, chia seeds are safe to be consumed every day. Nonetheless,
                because they're rich in fiber, begin with small portions and
                increase the quantity sluggishly to help discomfort in the
                digestive system. It's also judicious to soak chia seeds prior
                to consumption to enhance insipidity and nutrient application.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                4. How can I use flax seeds for better digestion?
              </h3>
              <p>
                Ground flax seeds are easier to digest than whole seeds. Add
                them to smoothies, yogurt, or oatmeal for maximum benefits.
                Drinking plenty of water when consuming flax seeds can also help
                in digestion, as they absorb humidity and expand in the stomach,
                promoting bowel chronicity.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                5. Do mustard seeds have medicinal properties?
              </h3>
              <p>
                Yes, mustard seeds retain anti-inflammatory and antimicrobial
                properties that may enhance digestion and overall well-being.
                They're used traditionally in herbal drugs for their warming
                action, which may stimulate the rotation and relieve respiratory
                affections. Mustard seeds also contain composites that promote
                detoxification and metabolic processes.
              </p>

              <h3 style={{ margin: "40px 0px" }}>About GlobalB2Bmart</h3>
              <p>
                GlobalB2Bmart is a leading online request portal created to gap
                the peak between buyers and merchandisers in multitudinous
                diligence. Our gate is committed to furnishing a continued
                business experience through access to organic products of high
                quality and other vital goods.
              </p>
              <h3 style={{ margin: "40px 0px" }}>What We give</h3>
              <p>
                A Large Product Portfolio We supply a vast array of organic
                seeds, husbandry goods, and other quality products.
              </p>
              <p>
                Trusted Supplier Network We bring companies in touch with
                trusted and certified suppliers to maintain quality and
                fictitiousness.
              </p>
              <p>
                Easy Global Trade Our point facilitates global trade through
                hassle-free deals and force chain support.
              </p>
              <h3 style={{ margin: "40px 0px" }}>Why Us?</h3>
              <p>
                Sustainability- Driven We deal in terrain-friendly and
                sustainable products to support a greener world.
              </p>
              <p>
                openings for Business Growth Whether you are a supplier or a
                buyer, we enable you to increase your request and form lasting
                business connections.
              </p>
              <p>
                stoner-Friendly Experience Our easy-to-use platform guarantees
                straightforward navigation, nippy product searching, and
                hassle-free deals.
              </p>
              <p>
                Search our vast force of organic seeds and husbandry produce on
                GlobalB2Bmart moment and shoot your business to new situations!
              </p>
            </div>

            <Link to={"/everyday-casual-wear-complete-companion"}>
              <button class="next-btn">Next</button>
            </Link>
          </article>
          <aside className="blog-sidebar">
            <h2>Business to business solutions</h2>
            <ul>
              <Link to={"/"}>
                <img
                  src="./assets/b2b12.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl2.jpeg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl3.jpeg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl4.jpeg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl5.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl6.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <Link to={"/"}>
                <img
                  src="./assets/grow1.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
            </ul>
          </aside>
        </main>
      </div>
    </>
  );
};

export default SeedsBlogs;
