import React from "react";
import { Link } from "react-router-dom";
import "./blog.css";
<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap');
</style>;

const CasualWearBlog = () => {
  return (
    <>
      <div style={{ fontFamily: "Oleo Script" }} className="blog-container">
        <header className="blog-header">
          {/* <h2 className="blog-intro" style={{ margin: "30px 0" }}>
            The Apple: A Nutritional Powerhouse with a Rich History
          </h2> */}
        </header>
        <main className="blog-main">
          <article className="blog-article">
            <Link to={"/"}>
              <img
                src="./assets/Casual-wear1.jpeg"
                alt="globalb2bmart"
                className="blog-image"
              />
            </Link>
            <div className="blog-body">
              {/* <div className="blog-intro">
                <h1>Introduction Here Now</h1>
              </div> */}
              <p>
                Casual wear and tear is an essential element of every person's
                wardrobe. It's a mix of comfort and fashion that will help you
                appear swish painlessly yet feel relaxed at the same time. You
                could be going out for a debonair day with your musketeers,
                working from home, or doing errands—in all similar cases, opting
                for the applicable casual wear and tear can help change the
                experience entirely. In this companion, we will bandy several
                aspects of everyday casual apparel, with emphasis on major
                apparel particulars such as denim jeans, casual covers, and
                casual pants.
              </p>

              <h3 style={{ margin: "40px 0px" }}>
                The significance of Everyday Casual Wear
              </h3>

              <Link to={"/"}>
                <img
                  src="./assets/Casual-wear2.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Casual road wear and tear is further than just getting dressed
                in the first thing that feels comfortable. It's getting dressed
                in such a way as to be tidy but relaxed at the same time. A
                serviceably named casual outfit can increase your confidence
                situations and make you feel great throughout the day. With a
                myriad of choices, from denim jeans to casual tees, the compass
                is measureless to produce the stylish casual wardrobe.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Denim Jeans The Complete Casual chief
              </h3>
              <Link to={"/"}>
                <img
                  src="./assets/Casual-wear3.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Denim jeans are really one of the most popular choices for
                casual wear and tear. They're protean, durable, and can be
                nominated in multitudinous ways. Then are some trending types of
                denim jeans that should be part of your wardrobe
              </p>

              <h3 style={{ margin: "40px 0px" }}>1. Wide Leg Jeans</h3>
              <Link to={"/"}>
                <img
                  src="./assets/Casual-wear4.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Wide-leg jeans have been a strong comeback in the fashion world.
                They give a comfortable fit and can be worn with casual covers
                or casual tees for a sharp yet relaxed look. Wearing them with
                lurkers or idlers can give them a more style element.
              </p>

              <h3 style={{ margin: "40px 0px" }}>2. Black Jeans</h3>
              <Link to={"/"}>
                <img
                  src="./assets/black-genes1.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                A black jeans brace is essential. They can be dressed down or up
                and are applicable for casual occasions and semi-formal events.
                You can wear them with a button-down shirt or a plain casual
                t-shirt to look neat and classic.
              </p>
              <h3 style={{ margin: "40px 0px" }}>3. White Jeans</h3>
              <Link to={"/"}>
                <img
                  src="./assets/white-jeans1.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                White jeans are a clean and contemporary style, perfect for
                spring and summer. They can be dressed up with brightly colored
                casual tees or light button-campo, so they're an excellent
                option for a simple yet sharp outfit.
              </p>
              <h3 style={{ margin: "40px 0px" }}>4. High Waisted Jeans</h3>
              <Link to={"/"}>
                <img
                  src="./assets/highh-wired-jeans.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                High-waisted jeans are both stylish and comfortable. They
                accentuate your midriff and can be worn with put-away casual
                covers for a meliorated look.
              </p>

              <h3 style={{ margin: "40px 0px" }}>5. Boot jeans</h3>
              <Link to={"/"}>
                <img
                  src="./assets/boot-jeans.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Charge-cut jeans have a slight flare towards the bottom and are
                a great fashion choice for those who seek a retro-casual
                appearance. They match both casual blouses and tees.
              </p>
              <h3 style={{ margin: "40px 0px" }}>6. Straight Jeans</h3>
              <Link to={"/"}>
                <img
                  src="./assets/straingh-jeans1.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Straight jeans are classic and give an erected-in but laid-back
                fit. They're accessible to match with casual blouses and can go
                with nearly every occasion.
              </p>

              <h3 style={{ margin: "40px 0px" }}>7. Slim Fit Jeans</h3>
              <Link to={"/"}>
                <img
                  src="./assets/slim-fit-jeans.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                For a swish and contemporary appearance, slim-fit jeans are the
                ideal choice. They give a fitted look and brace well with
                t-shirts or button-campo.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Casual Shirts and T-Shirts The Ideal Pairing for Denim
              </h3>
              <p>
                Denim jeans alone aren't enough; you need the applicable topwear
                to match them. Some great options include
              </p>
              <h3 style={{ margin: "40px 0px" }}>Casual Shirts</h3>
              <Link to={"/"}>
                <img
                  src="./assets/casual-shirt.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Casual shirts are available in all kinds of styles, fabrics, and
                tinges. They're ideal for incorporating a slightly formal touch
                to your day-to-day appearance.
              </p>
              <p>
                Button-down shirts are a dateless style that looks good with
                jeans and relaxed trousers.
              </p>
              <p>
                Linen Shirts Suitable for warm rainfall, furnishing a light and
                elegant appearance.
              </p>
              <p>
                Checked Shirts Great option for incorporating some print and
                visual interest into your relaxed wardrobe.
              </p>
              <h3 style={{ margin: "40px 0px" }}>Casual T-Shirts</h3>
              <Link to={"/"}>
                <img
                  src="./assets/casual-tshirt1.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Casual t-shirts are a staple in any wardrobe. They can be paired
                fluently with denim jeans or casual trousers.
              </p>
              <p>
                Solid T-Shirts Simple yet necessary, ideal for pairing with any
                type of denim.
              </p>
              <p>
                Graphic T-Shirts A sportful and creative way to express
                personality through fashion.
              </p>
              <p>
                Banded T-Shirts These give a fashionable touch without being too
                flashy.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Casual Trousers A protean Option{" "}
              </h3>
              <Link to={"/"}>
                <img
                  src="./assets/casual-trouser.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>

              <p>
                While denim jeans run the show when it comes to casual wear and
                tear, casual trousers give a break for people seeking variety.
              </p>
              <p>
                Chinos Stylish and comfortable, chinos are an excellent choice
                for a dressed-up casual look.
              </p>
              <p>
                Cargo Pants With their mileage-inspired aesthetic, weight pants
                bring that rugged edge to casual wear and tear.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Nutritive Value of Organic Seeds
              </h3>
              <p>
                Joggers Ideal for a relaxed look, joggers give style and
                comfort.
              </p>

              <h3 style={{ margin: "40px 0px" }}>
                How to Style Everyday Casual Wear
              </h3>
              <h3 style={{ margin: "40px 0px" }}>1. Keeping It Simple</h3>
              <Link to={"/"}>
                <img
                  src="./assets/keep-it-simple.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Wear a simple t-shirt with skinny-fit jeans and loafers for a
                classic and easy style.
              </p>

              <h3 style={{ margin: "40px 0px" }}>2. Layering to Style</h3>
              <Link to={"/"}>
                <img
                  src="./assets/layring-style.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Wear a button-down top over a simple t-shirt with straight pants
                and idlers to add to your appearance.
              </p>

              <h3 style={{ margin: "40px 0px" }}>
                3. Blending Colors and Textures
              </h3>
              <Link to={"/"}>
                <img
                  src="./assets/blending-texture1.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Wear black jeans with a white button-down shirt for a dateless
                snap look.
              </p>
              <h3 style={{ margin: "40px 0px" }}>4. Accessorizing Wisely</h3>
              <Link to={"/"}>
                <img
                  src="./assets/accessring-wearing.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <p>
                Wear a cool watch, belt, and sunglasses to dress up your casual
                style without making it too formal.
              </p>

              <h3 style={{ margin: "40px 0px" }}>FAQs </h3>
              <Link to={"/"}>
                <img
                  src="./assets/faq9.png"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>

              <h3 style={{ margin: "40px 0px" }}>
                Q1: Which jeans are most suitable for wearing on a day-to-day
                basis?
              </h3>
              <p>
                Slim fit, straight, and wide-leg jeans are the top picks for
                wearing on a diurnal base because of their comfortability and
                rigidity.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Q2: Is white jeans an ideal casual wear and tear to wear
                casually?
              </h3>
              <p>
                A Indeed! White jeans are perfect to wear as a casual outfit and
                can be paired with various casual t-shirts or plain button-up
                shirts.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Q3: Which shoes would you recommend wearing with casual
                trousers?
              </h3>
              <p>
                Lurkers, idlers, and casual thrills are perfect footwear options
                for casual trousers.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Q4: How do I elect the perfect casual shirt?
              </h3>
              <p>
                A Go for soft, permeable fabrics similar as cotton or linen and
                choose colors and patterns according to your personality and
                event.
              </p>
              <h3 style={{ margin: "40px 0px" }}>
                Q5 Are charge cut jeans still trendy?
              </h3>
              <p>
                Absolutely! Charge-cut jeans have returned and can be worn with
                a trendy twist along with casual shirts and lurkers.
              </p>

              <h3 style={{ margin: "40px 0px" }}>About GlobalB2BMart</h3>
              <p>
                GlobalB2BMart is one of the top B2B commerce online, linking
                buyers and suppliers from different ies. On our point, we
                feature a wide array of products and services that can help
                businesses expand and flourish. We hope to give you precious
                information and fashion updates through our good-quality blog
                content so that you can stay ahead in your business. Anticipate
                further instructional posts and developments soon!
              </p>
            </div>

            <Link to={"/the-ultimate-footwear-collection-guide"}>
              <button class="next-btn">Next</button>
            </Link>
          </article>
          <aside className="blog-sidebar">
            <h2>Business to business solutions</h2>
            <ul>
              <Link to={"/"}>
                <img
                  src="./assets/b2b12.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl2.jpeg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl3.jpeg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl4.jpeg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl5.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
                <img
                  src="./assets/mainbl6.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
              <Link to={"/"}>
                <img
                  src="./assets/grow1.jpg"
                  alt="globalb2bmart"
                  className="blog-image"
                />
              </Link>
            </ul>
          </aside>
        </main>
      </div>
    </>
  );
};

export default CasualWearBlog;
